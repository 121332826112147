const env = require('../../helpers/env');
const { isCallbackBasedAPI } = require('./electron_desktop_capturer_helper');
let isElectronScreenSharingSource = require('./is_electron_screen_sharing_source');

let desktopCapturer = null;

// Legacy versions of Electron have a callback-based method
const getSourcesUsingCallback = (opts, callback) => {
  desktopCapturer.getSources(opts, callback);
};

// More recent versions of Electron has a promise-based method
const getSourcesUsingPromise = (opts, callback) => {
  desktopCapturer.getSources(opts).then(
    (sources) => {
      callback(undefined, sources);
    },
    callback
  );
};

const getSourcesAdapter = () =>
  (isCallbackBasedAPI ? getSourcesUsingCallback : getSourcesUsingPromise);

const getSourcesWrapper = (source, callback) => {
  const getSources = getSourcesAdapter();

  getSources({ types: [source] }, (error, sources) => {
    if (error) {
      callback(error);
      return;
    }

    // For now just pick the first source. In future we need to implement a selection
    // dialog.
    const foundSource = sources[0];

    if (!foundSource) {
      callback(new Error('Could not find Electron source for screensharing'));
      return;
    }

    const constraints = {
      audio: false,
      video: {
        mandatory: {
          chromeMediaSource: source,
          chromeMediaSourceId: foundSource.id,
        },
      },
    };

    callback(undefined, constraints);
  });
};

module.exports = function electronExtensionHelperFactory(deps = {}) {
  try {
    // Using a dynamic require here because that's the way we get desktopCapturer in electron, and
    // that's not something to bundle in.

    // We are requiring electron here to see if it's available, it's intentionally not packaged
    // and not listed in the package.json for this repository. It is expected to be found only if
    // we are running inside an Electron application.

    const globalContext = () => {
      // We need to search for the require function either in the current
      // global or, if its a window, one of its parents. This is necessary
      // in case OT is used from within an iframe (like our integration
      // tests).
      let current = global;
      while (
        typeof current.require !== 'function' &&
          current.parent &&
          current.parent !== current
      ) {
        current = current.parent;
      }
      return current;
    };

    const getDesktopCapturer = () => {
      const current = globalContext();

      // In case the option 'contextIsolation' is true, the require function will be undefined,
      // so we'll expect the desktopCapturer to be exposed in window.electron.desktopCapturer by
      // the app layer within a preload file.
      // See https://www.electronjs.org/docs/tutorial/context-isolation
      const electron = current.require ? current.require('electron') : current.electron;
      return electron.desktopCapturer;
    };

    desktopCapturer = deps.desktopCapturer ||
      // Don't try to import unless in electron environment, else `require` will
      // dispatch an error message if `electron` is not found
      (env.isElectron ? getDesktopCapturer() : null);

    isElectronScreenSharingSource = deps.isElectronScreenSharingSource
      || isElectronScreenSharingSource;
  } catch (e) {
    // Ignore
  }

  return {
    isSupportedInThisBrowser: desktopCapturer !== null,
    autoRegisters: true,
    extensionRequired: false,
    getConstraintsShowsPermissionUI: false,
    sources: {
      screen: true,
      application: true,
      window: true,
      browser: true,
    },

    register: () => ({
      isInstalled: callback => callback(true),
      getConstraints(source, constraints, callback) {
        if (isElectronScreenSharingSource(source)) {
          // User passed in a custom screen sharing source, so no need to call
          // "getSources" (cause we already have one).
          callback(undefined, { audio: true, video: false });
        } else {
          getSourcesWrapper(source, callback);
        }
      },
    }),
  };
};
