exports.adaptIceServers = function adaptIceServers(iceServers) {
  return iceServers.map(iceServer => ({
    url: iceServer.url, // deprecated
    urls: iceServer.urls || [iceServer.url],
    username: iceServer.username,
    credential: iceServer.credential,
  }));
};

exports.parseIceServers = function parseIceServers(message) {
  let iceServers;

  try {
    iceServers = JSON.parse(message.data).content.iceServers;
  } catch (e) {
    return [];
  }

  return exports.adaptIceServers(iceServers);
};
