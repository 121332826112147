const ExceptionCodes = require('../exception_codes.js');
const errors = require('../Errors.js');

module.exports = {
  NOT_CONNECTED: {
    code: errors.NOT_CONNECTED,
    message: 'Cannot use forceMute functionality. You are not connected to the session.',
    exceptionCode: ExceptionCodes.NOT_CONNECTED,
  },
  PERMISSION_DENIED: {
    code: errors.PERMISSION_DENIED,
    message: 'This token does not allow forceMute usage. The role must be at least `moderator` to enable this ' +
    'functionality',
    exceptionCode: ExceptionCodes.UNABLE_TO_FORCE_MUTE,
  },
  INVALID_PARAMETER: {
    code: errors.INVALID_PARAMETER,
    message: 'Invalid Parameter. Check that you have passed valid parameter values into the method call.',
    exceptionCode: ExceptionCodes.INVALID_PARAMETER,
  },
  NOT_FOUND: {
    code: errors.NOT_FOUND,
    message: 'The stream does not exist.',
  },
  UNEXPECTED_SERVER_RESPONSE: {
    code: errors.UNEXPECTED_SERVER_RESPONSE,
    message: 'An unexpected error occurred.',
    exceptionCode: ExceptionCodes.UNEXPECTED_SERVER_RESPONSE,
  },
};
