/* eslint-disable global-require */

module.exports = (deps = {}) => {
  const {
    hasAudioOutputApiSupport,
    hasDevice,
  } = deps.deviceHelpers || require('../helpers/device_helpers')();
  const getAudioOutputDevices = deps.getAudioOutputDevices || require('../ot/get-audio-output-devices');
  const updateSubscribersSinkId = deps.updateSubscribersSinkId
    || require('./update-subscribers-sinkid');
  const otError = require('../helpers/otError')();
  const Errors = require('../ot/Errors');

  /**
   * Sets the new audio output device.
   * <p>
   *   The method returns a Promise which resolves when the operation completes
   *   successfully. If there is an error, the promise is rejected.
   * </p>
   *
   * @see <a href="#getAudioOutputDevices">OT.getAudioOutputDevices()</a>
   * @see <a href="#getActiveAudioOutputDevice">OT.getActiveAudioOutputDevice()</a>
   * @method OT.setAudioOutputDevice
   * @memberof OT
   * @return {Promise} A promise that resolves when the operation completes successfully.
   * If there is an error, the promise is rejected.
   */
  const setAudioOutputDevice = async (deviceId) => {
    // Let's check if this browser supports setSinkId
    if (!hasAudioOutputApiSupport()) {
      throw otError(
        Errors.UNSUPPORTED_BROWSER,
        new Error('setAudioOutputDevice is not supported in your browser.')
      );
    }

    // Check if device exists
    const devices = await getAudioOutputDevices();
    const isValidDevice = hasDevice(devices, deviceId);
    if (!isValidDevice) {
      throw otError(
        Errors.INVALID_AUDIO_OUTPUT_SOURCE,
        new Error('The requested device is not valid.')
      );
    }

    // Let's change the sinkId now on all the subscribers, if any.
    try {
      await updateSubscribersSinkId(deviceId);
    } catch (error) {
      // This error description was copied from:
      // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/setSinkId#exceptions
      throw otError(
        Errors.REQUESTED_DEVICE_PERMISSION_DENIED,
        new Error('No permission to use the requested device.')
      );
    }
  };

  return setAudioOutputDevice;
};
