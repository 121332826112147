const isSessionProxiedDefault = require('../../helpers/isSessionProxied');

module.exports = (session, deps = {}) => {
  const { isSessionProxied = isSessionProxiedDefault } = deps;

  if (!session) {
    // Logs must be tagged to the API key, which we'll only know after we
    // connect to a session.
    return false;
  }

  if (isSessionProxied(session.id)) {
    // Since MP doesn't support IP proxy yet, so we need to disable logging when
    // the session is going through a proxy, else data will leak
    return false;
  }

  // Logs must be linked the the API
  return !!session.apiKey;
};
