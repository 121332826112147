// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, global-require, no-underscore-dangle, no-shadow */

const defineProperties = require('../../helpers/defineProperties.js');

module.exports = function MicrophoneFactory() {
  /*
   * A Publishers Microphone.
   *
   * TODO
   * * bind to changes in mute/unmute/volume/etc and respond to them
   */
  return function Microphone(webRTCStream, muted) {
    let _muted;

    defineProperties(this, {
      muted: {
        get() {
          return _muted;
        },
        set(muted) {
          if (_muted === muted) { return; }

          _muted = muted;

          const audioTracks = webRTCStream.getAudioTracks();

          for (let i = 0, num = audioTracks.length; i < num; ++i) {
            audioTracks[i].enabled = !_muted;
          }
        },
      },
    });

    // Set the initial value
    if (muted !== undefined) {
      this.muted(muted === true);
    } else if (webRTCStream.getAudioTracks().length) {
      this.muted(!webRTCStream.getAudioTracks()[0].enabled);
    } else {
      this.muted(false);
    }
  };
};
