const generateKey = async (sessionId, secret) => {
  // isExtractableKeyMaterial and isExtractableDerivedKey indicates whether it will be possible
  // to export the key using SubtleCrypto.exportKey() or SubtleCrypto.wrapKey().
  const isExtractableKeyMaterial = false;
  const isExtractableDerivedKey = true;

  const enc = new TextEncoder();
  const keyMaterial = await window.crypto.subtle.importKey(
    'raw',
    enc.encode(secret),
    { name: 'PBKDF2' },
    isExtractableKeyMaterial,
    ['deriveBits', 'deriveKey']
  );
  return window.crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt: enc.encode(sessionId),
      iterations: 100000,
      hash: 'SHA-256',
    },
    keyMaterial,
    { name: 'AES-CTR', length: 256 },
    isExtractableDerivedKey,
    ['encrypt', 'decrypt']
  );
};

module.exports = generateKey;
