const env = require('../helpers/env.js');
const hasOpenTokSupport = require('./hasOpenTokSupport.js').once;

// TODO Remove all transport stuff, that belongs to the messaging layer not the Media layer.
// Indicates if the browser supports bundle
//
// Broadly:
// * Firefox support bundle
// * Chrome support bundle
// * We assume NodeJs supports bundle (e.g. 'you're on your own' mode)
//
module.exports = () =>
  hasOpenTokSupport() && (
    env.name === 'Chrome' ||
    env.name === 'Firefox' ||
    env.name === 'Node' ||
    env.name === 'Safari' ||
    env.name === 'Edge'
  );
