import * as socketCloseCodes from '../socketCloseCodes';

export default class SocketError {
  code;
  message;

  constructor(
    code = socketCloseCodes.codes.CLOSE_FALLBACK_CODE,
    message = socketCloseCodes.messages[code] || 'No message available from code.'
  ) {
    this.code = code;
    this.message = message;
  }
}
