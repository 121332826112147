// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-restricted-syntax, no-prototype-builtins */

const find = require('lodash/find');
const DataChannel = require('./data_channel.js');

// Contains a collection of DataChannels for a particular RTCPeerConnection
//
// @param [RTCPeerConnection] pc A native peer connection object
//
// @constructor
// @private
//
module.exports = function PeerConnectionChannels(pc) {
  // / Private Data
  let channels = [];
  const api = {};

  let lastQos = {
    sentMessages: 0,
    recvMessages: 0,
  };

  // / Private API

  const remove = function remove(channel) {
    channels.filter(c => channel !== c);
  };

  const add = function add(nativeChannel) {
    const channel = new DataChannel(nativeChannel);
    channels.push(channel);

    channel.on('close', () => {
      remove(channel);
    });

    return channel;
  };

  // / Public API

  api.add = function (label, options) {
    return add(pc.createDataChannel(label, options));
  };

  api.addMany = function (newChannels) {
    for (const label in newChannels) {
      if (newChannels.hasOwnProperty(label)) {
        api.add(label, newChannels[label]);
      }
    }
  };

  api.get = function (label, options) {
    return find(channels, channel => channel.equals(label, options));
  };

  api.getOrAdd = function (label, options) {
    let channel = api.get(label, options);
    if (!channel) {
      channel = api.add(label, options);
    }

    return channel;
  };

  api.getQosData = function () {
    const qosData = {
      sentMessages: 0,
      recvMessages: 0,
    };

    channels.forEach((channel) => {
      qosData.sentMessages += channel.getQosData().sentMessages;
      qosData.recvMessages += channel.getQosData().recvMessages;
    });

    return qosData;
  };

  api.sampleQos = function () {
    const newQos = api.getQosData();

    const sampleQos = {
      sentMessages: newQos.sentMessages - lastQos.sentMessages,
      recvMessages: newQos.recvMessages - lastQos.recvMessages,
    };

    lastQos = newQos;

    return sampleQos;
  };

  api.destroy = function () {
    channels.forEach((channel) => {
      channel.close();
    });

    channels = [];
  };

  // / Init

  pc.addEventListener('datachannel', (event) => {
    add(event.channel);
  });

  return api;
};
