// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign */

const applySdpTransform = require('./applySdpTransform.js');
const env = require('../../helpers/env.js');
const logging = require('../../helpers/log')('offerProcessor');
const SDPHelpers = require('./sdp_helpers.js');
const testSupportVideo = require('./testSupportVideo.js');

// Attempt to completely process +offer+. This will:
// * set the offer as the remote description
// * create an answer and
// * set the new answer as the location description
//
// If there are no issues, the +success+ callback will be executed on completion.
// Errors during any step will result in the +failure+ callback being executed.
//
module.exports = function offerProcessor(
  peerConnection,
  RTCPeerConnection,
  RTCSessionDescription,
  NativeRTCSessionDescription,
  sdpTransforms,
  offer,
  codecFlags,
  p2p,
  success,
  failure,
  onVideoSupported,
  replaceBaselineProfile,
  sourceStreamId
) {
  const generateErrorCallback = (message, prefix) => (
    (errorReason) => {
      logging.error(message);
      logging.error(errorReason);

      if (failure) {
        failure(message, errorReason, prefix);
      }
    }
  );

  const setLocalDescription = (answer) => {
    const enableStereo = SDPHelpers.hasSendStereo(offer.sdp);
    const enableDtx = SDPHelpers.hasSendDtx(offer.sdp);
    const audioBitrate = SDPHelpers.getAudioBitrate(offer.sdp);

    const transformedSdp = applySdpTransform(
      sdpTransforms,
      'local',
      'answer',
      {
        enableStereo,
        enableDtx,
        audioBitrate,
        replaceBaselineProfile,
        codecFlags,
        sourceStreamId,
      },
      answer.sdp
    );

    const newLocalAnswer = new NativeRTCSessionDescription({
      type: answer.type,
      sdp: transformedSdp.local,
    });

    const newRemoteAnswer = {
      type: answer.type,
      sdp: transformedSdp.remote,
    };

    return peerConnection
      .setLocalDescription(newLocalAnswer)
      .then(() => success(newRemoteAnswer))
      .catch(generateErrorCallback('Error while setting LocalDescription', 'SetLocalDescription'));
  };

  const createAnswer = () => (
    peerConnection
      .createAnswer()
      .then(setLocalDescription)
      .catch(generateErrorCallback('Error while setting createAnswer', 'CreateAnswer'))
  );

  const newOffer = new NativeRTCSessionDescription({
    type: offer.type,
    sdp: applySdpTransform(
      sdpTransforms,
      'remote',
      'offer',
      { replaceBaselineProfile },
      offer.sdp
    ).local,
  });

  const errorCallback = generateErrorCallback(
    'Error while setting RemoteDescription',
    'SetRemoteDescription'
  );

  const finalOfferPromise = testSupportVideo(
    newOffer,
    p2p,
    env,
    RTCPeerConnection,
    RTCSessionDescription
  )
    .then(() => {
      onVideoSupported(true);
      return newOffer;
    })
    .catch((err) => {
      onVideoSupported(false);
      if (!SDPHelpers.hasMediaType(newOffer.sdp, 'audio')) {
        throw new Error('Unsupported video without audio for fallback');
      }
      logging.debug('Couldn\'t set remote description', newOffer, err, ', trying without video');

      const offerWithoutVideo = new NativeRTCSessionDescription({
        type: offer.type,
        sdp: SDPHelpers.disableMediaType(newOffer.sdp, 'video'),
      });

      logging.debug('offer without video', offerWithoutVideo.sdp);

      return offerWithoutVideo;
    });

  return finalOfferPromise
    .then(finalOffer => peerConnection.setRemoteDescription(finalOffer))
    .then(createAnswer)
    .catch(errorCallback);
};
