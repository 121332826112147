/* eslint-disable global-require */

const Analytics = require('../helpers/analytics');

const defaultAnalytics = new Analytics();

module.exports = function reportIssueFactory(deps = {}) {
  /** @type defaultAnalytics */
  const analytics = deps.analytics || defaultAnalytics;
  const errors = deps.errors || require('./Errors.js');
  const ExceptionCodes = deps.ExceptionCodes || require('./exception_codes.js');
  const otError = deps.otError || require('../helpers/otError.js')();
  const sessionObjects = deps.sessionObjects || require('./session/objects.js');
  const uuid = deps.uuid || require('uuid');

  /**
  * Report that your app experienced an issue. You can use the issue ID with
  * <a href="http://tokbox.com/developer/tools/Inspector">Inspector</a> or when discussing
  * an issue with the TokBox support team.
  *
  * @param completionHandler {Function} A function that is called when the call to this method
  * succeeds or fails. This function has two parameters. The first parameter is an
  * <a href="Error.html">Error</a> object that is set when the call to the
  * <code>reportIssue()</code> method fails (for example, if the client is not connected to the
  * network) or <code>null</code> when the call to the <code>reportIssue()</code> method succeeds.
  * The second parameter is set to the report ID (a unique string) when the call succeeds.
  *
  * @method OT.reportIssue
  * @memberof OT
  */
  return function reportIssue(completionHandler = () => {}) {
    const reportIssueId = uuid();

    function onDone(err) {
      if (err) {
        completionHandler(otError(
          errors.REPORT_ISSUE_FAILED,
          new Error('Error calling OT.reportIssue(). Check the client\'s network connection.'),
          ExceptionCodes.REPORT_ISSUE_ERROR
        ));
      } else {
        completionHandler(null, reportIssueId);
      }
    }

    if (sessionObjects.sessions.length() === 0) {
      analytics.logEvent(
        {
          action: 'ReportIssue',
          variation: 'Event',
          payload: { reportIssueId },
        },
        null,
        onDone
      );
    } else {
      Promise.all(sessionObjects.sessions.map(session =>
        session.reportIssue({ id: reportIssueId })
      ))
        .then(
          () => onDone(),
          err => onDone(err)
        );
    }
  };
};
