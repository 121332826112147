const screenSharingSources = require('./screenSharingSources');
const isElectronScreenSharingSource = require('../screensharing/is_electron_screen_sharing_source');
const env = require('../../helpers/env');

module.exports = (videoSource) => {
  if (env.isElectron) {
    // Electron only supports a subset of `screenSharingSources` available on
    // other user agents
    if (videoSource === 'screen') {
      return true;
    }

    // Checks if this video source is a custom screensharing source
    return isElectronScreenSharingSource(videoSource);
  }

  return screenSharingSources.indexOf(videoSource) > -1;
};
