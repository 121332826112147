const sessionObjects = require('./session/objects.js');

module.exports = () => Promise.all(sessionObjects.subscribers.where().map((sub) => {
  if (!sub.isAudioBlocked()) {
    return undefined;
  }

  /**
   * Causes subscribers' audio to play back in browsers where audio is blocked.
   * You must call this method in response to an HTML element dispaching
   * a <code>click</code> event.
   * <p>
   * You only need to call this method if you disable display of
   * the Subscriber's default audio playback icon (by setting the
   * <code>style.audioBlockedDisplayMode</code> property of the
   * <code>options</code> parameter of the
   * <a href="Session.html#subscribe">Session.subscribe()</a> method).
   * You may call this method in response to the user clicking an HTML element
   * that you display on the page.
   * <p>
   * The Subscriber dispatches an
   * <a href="Subscriber.html#event:audioBlocked">audioBlocked</a> event
   * if audio is blocked. In response to that event, you can display a UI
   * element to notify the end user to respond to playback subscriber audio.
   * Call the <code>OT.unblockAudio()</code> method in response to the user
   * clicking a DOM element.
   * <p>
   * <i>Note:</i> Subscriber audio is also unblocked automatically if
   * the local client gains access to the camera or microphone (for instance,
   * in response to a successful call to <code>OT.initPublisher()</code>).
   *
   * @returns Promise The promise resolves if audio playback succeeds
   * on all blocked subscribers. The promise is rejected if playback fails.
   * See <a href="Error.html">Error</a> for details.
   *
   * @see <a href="Subscriber.html#isAudioBlocked">Subscriber.isAudioBlocked()</a>
   * @see The <a href="Subscriber.html#event:audioBlocked">audioBlocked</a>
   * and <a href="Subscriber.html#event:audioUnblocked">audioUnblocked</a>
   * Subscriber events
   *
   * @method OT.unblockAudio
   * @memberof OT
   */
  return sub._.unblockAudio();
}));
