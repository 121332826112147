const IntervalRunner = require('../ot/interval_runner');

module.exports = ({ connectivityState, logAttempt }) => {
  const connectivityAttemptPinger = new IntervalRunner(logAttempt, 1 / 5, 6);

  connectivityState.observe({
    onEnterConnecting() {
      connectivityAttemptPinger.start();
    },
    onEnterDisconnected() {
      connectivityAttemptPinger.stop();
    },
    onEnterConnected() {
      connectivityAttemptPinger.stop();
    },
  });
};
