const defaultStaticConfig = require('../../../helpers/StaticConfig')().onlyLocal();

// Returns URL of CDN on which ML assets are hosted
const getCdnUrl = (staticConfig) => {
  const { apiUrl } = staticConfig;
  const isProd = apiUrl.includes('anvil.opentok.com');
  const host = isProd ? 'https://static.opentok.com' : 'https://www.dev.tokbox.com';

  return `${host}/v1.3.0/ml-transformers`;
};

// Default configuration that may be shared by all ML transforms
const DefaultConfig = {
  getConfig: (deps = {}) => {
    const { staticConfig = defaultStaticConfig } = deps;
    const cdnUrl = getCdnUrl(staticConfig);

    return {
      wasmAssetUriPath: `${cdnUrl}/`,
      tfliteAssetUriPath: `${cdnUrl}/models/`,
    };
  },
};

module.exports = DefaultConfig;
