module.exports = function eventHelper(object) {
  const eventHandlers = [];

  return {
    on(eventName, handler, ...args) {
      eventHandlers.push({ eventName, handler, args });
      if (object.on) {
        object.on(eventName, handler);
      } else if (object.addEventListener) {
        object.addEventListener(eventName, handler, ...args);
      } else {
        throw new Error('Object does not support events', object);
      }
    },
    off(eventName, handler, ...args) {
      if (object.off) {
        object.off(eventName, handler);
      } else if (object.removeEventListener) {
        object.removeEventListener(eventName, handler, ...args);
      } else {
        throw new Error('Object does not support events', object);
      }
    },
    once(eventName, handler, ...args) {
      eventHandlers.push({ eventName, handler, args });
      object.once(eventName, handler);
    },
    removeAll() {
      eventHandlers.forEach(({ eventName, handler, args }) => {
        this.off(eventName, handler, ...args);
      });
      eventHandlers.splice(0, eventHandlers.length);
    },
  };
};
