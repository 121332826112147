const ExceptionCodes = require('../ot/exception_codes.js');
const OTErrorClass = require('../ot/ot_error_class.js');

const knownErrorCodes = [400, 403, 409, ExceptionCodes.CONNECTION_LIMIT_EXCEEDED];

const reasons = {
  CONNECT: 'ConnectToSession',
  SESSION_STATE: 'GetSessionState',
};

/*
 * Converts an error from RumorSocket to use generic exception codes
 * @param {Error} error - Error object with reason property
 * @return {Object} The converted error code and message
 * @property {number} code
 * @property {string} message
 */
module.exports = function convertRumorError(error) {
  let code;
  let message;

  if (error.reason === reasons.CONNECT && error.code === ExceptionCodes.CONNECT_FAILED) {
    code = error.code;
    message = OTErrorClass.getTitleByCode(error.code);
  } else if (error.code && knownErrorCodes.indexOf(Number(error.code)) > -1) {
    code = ExceptionCodes.CONNECT_FAILED;
    switch (error.reason) {
      case reasons.CONNECT:
        switch (error.code) {
          case ExceptionCodes.CONNECTION_LIMIT_EXCEEDED:
            code = error.code;
            message = 'Cannot connect -- the limit for concurrent connections to the session ' +
              'has been reached';
            break;
          default:
            message = 'Received error response to connection create message.';
            break;
        }
        break;
      case reasons.SESSION_STATE:
        message = 'Received error response to session read';
        break;
      default:
        message = '';
        break;
    }
  } else {
    code = ExceptionCodes.UNEXPECTED_SERVER_RESPONSE;
    message = 'Unexpected server response. Try this operation again later.';
  }

  return { code, message };
};
