// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-underscore-dangle, no-param-reassign */

const logging = require('../helpers/log')('GlobalExceptionHandler');
const eventing = require('../helpers/eventing');
const eventNames = require('../helpers/eventNames');
const errorCodeToErrorTitleMap = require('../helpers/errorCodeToErrorTitleMap');
const Events = require('./events.js')();

/**
 * The Error class is used to define the error object passed into completion handlers.
 * Each of the following methods, which execute asynchronously, includes a
 * <code>completionHandler</code> parameter:
 *
 * <ul>
 *   <li><a href="OT.html#initPublisher">OT.initPublisher()</a></li>
 *   <li><a href="OT.html#reportIssue">OT.reportIssue()</a></li>
 *   <li><a href="OT.html#setProxyUrl">OT.setProxyUrl()</a></li>
 *   <li><a href="Publisher.html#setVideoSource">Publisher.setVideoSource()</a></li>
 *   <li><a href="Session.html#connect">Session.connect()</a></li>
 *   <li><a href="Session.html#forceDisconnect">Session.forceDisconnect()</a></li>
 *   <li><a href="Session.html#forceUnpublish">Session.forceUnpublish()</a></li>
 *   <li><a href="Session.html#publish">Session.publish()</a></li>
 *   <li><a href="Session.html#subscribe">Session.subscribe()</a></li>
 *   <li><a href="Subscriber.html#getStats">Subscriber.getStats()</a></li>
 * </ul>
 *
 * <p>
 * The Error class also defines the Error thrown when the promise returned by one of
 * the following methods is rejected:
 *
 * <ul>
 *   <li><a href="OT.html#unblockAudio">OT.unblockAudio()</a></li>
 *   <li><a href="Publisher.html#applyVideoFilter">Publisher.applyVideoFilter()</a></li>
 *   <li><a href="Publisher.html#clearVideoFilter">Publisher.clearVideoFilter()</a></li>
 *   <li><a href="Publisher.html#getRtcStatsReport">Publisher.getRtcStatsReport()</a></li>
 *   <li><a href="Session.html#forceMuteAll">Session.forceMuteAll()</a></li>
 *   <li><a href="Session.html#forceMuteStream">Session.forceMuteStream()</a></li>
 *   <li><a href="Subscriber.html#getRtcStatsReport">Subscriber.getRtcStatsReport()</a></li>
 * </ul>
 *
 * <p>
 * The <code>completionHandler</code> parameter is a function that is called when the call to
 * the asynchronous method succeeds or fails. If the asynchronous call fails, the completion
 * handler function is passed an error object (defined by the Error class). The <code>code</code>
 * and <code>message</code> properties of the error object provide details about the error.
 *
 * <p>
 * <b>Deprecation notice:</b>
 * <code>OT.Error</code> will not be defined in an upcoming version of OpenTok. When this happens,
 * errors emitted by the SDK will simply be instances of standard <code>Error</code> objects.
 * If your code currently checks <code>if (error instanceof OT.Error)</code> please replace it
 * <code>if (/^OT_/.test(error.name))</code>. (The <code>name</code> property for all OpenTok
 * errors with begins with <code>'OT_'</code>.) Note however that this code is for the
 * purposes of smoothly upgrading, and it may be more appropriate for your code to simply compare
 * against the specific error you want to handle, such as
 * <code>if (error.name === 'OT_USER_MEDIA_ACCESS_DENIED')</code>.
 *
 * @property {Number} code Deprecated &mdash; the error code. Use the <code>name</code> property,
 * not the <code>code</code> property, to identify the error programatically.
 *
 * <p>
 *   The <code>code</code> property of the Error object can be set to one of the values
 *   listed below. This property can also be set to other values, and you may want to report
 *   this value when discussing an issue with TokBox support.
 * </p>
 *
 * <p>Errors when calling <code>Session.connect()</code>:</p>
 *
 * <table class="docs_table"><tbody>
 * <tr>
 *   <td><b><code>code</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td>1004</td>
 *   <td>Authentication error. Check the error message for details. This error can result if you
 *     pass in an expired token when trying to connect to a session. It can also occur if you pass
 *     in an invalid token or API key. Make sure that you are generating the token using the
 *     current version of one of the
 *     <a href="http://tokbox.com/opentok/libraries/server">OpenTok server SDKs</a>.</td>
 * </tr>
 * <tr>
 *   <td>1005</td>
 *   <td>Invalid Session ID. Make sure you generate the session ID using the current version of
 *     one of the <a href="http://tokbox.com/opentok/libraries/server">OpenTok server
 *     SDKs</a>.</td>
 * </tr>
 * <tr>
 *   <td>1006</td>
 *   <td>Connect Failed. Unable to connect to the session. You may want to have the client check
 *     the network connection.</td>
 * </tr>
 * <tr>
 *  <td>1026</td>
 *  <td>Terms of service violation: export compliance. See the
 *    <a href="https://tokbox.com/support/tos">Terms of Service</a>.</td>
 * </tr>
 * <tr>
 *  <td>1027</td>
 *  <td>Connection limit exceeded. The client tried to connect to a session that has exceeded
 *  limit for simultaneous connections.</td>
 * </tr>
 * <tr>
 *   <td>2001</td>
 *   <td>Connect Failed. Unexpected response from the OpenTok server. Try connecting again
 *     later.</td>
 * </tr>
 * </tbody></table>
 *
 * <p>Errors when calling <code>Session.forceDisconnect()</code>:</p>
 *
 * <table class="docs_table"><tbody>
 * <tr>
 *   <td><b>
 *       <code>code</code>
 *     </b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td>1010</td>
 *   <td>The client is not connected to the OpenTok session. Check that client connects
 *     successfully and has not disconnected before calling forceDisconnect().</td>
 * </tr>
 * <tr>
 *   <td>1520</td>
 *   <td>Unable to force disconnect. The client's token does not have the role set to moderator.
 *     Once the client has connected to the session, the <code>capabilities</code> property of
 *     the Session object lists the client's capabilities.</td>
 * </tr>
 * </tbody></table>
 *
 * <p>Errors when calling <code>Session.forceUnpublish()</code>:</p>
 *
 * <table class="docs_table"><tbody>
 * <tr>
 *   <td><b><code>code</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td>1010</td>
 *   <td>The client is not connected to the OpenTok session. Check that client connects
 *     successfully and has not disconnected before calling forceUnpublish().</td>
 * </tr>
 * <tr>
 *   <td>1530</td>
 *   <td>Unable to force unpublish. The client's token does not have the role set to moderator.
 *     Once the client has connected to the session, the <code>capabilities</code> property of
 *     the Session object lists the client's capabilities.</td>
 * </tr>
 * <tr>
 *   <td>1535</td>
 *   <td>Force Unpublish on an invalid stream. Make sure that the stream has not left the
 *     session before you call the <code>forceUnpublish()</code> method.</td>
 * </tr>
 * </tbody></table>
 *
 * <p>Errors when calling <code>Session.publish()</code>:</p>
 *
 * <table class="docs_table"><tbody>
 * <tr>
 *   <td><b><code>code</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td>1010</td>
 *   <td>The client is not connected to the OpenTok session. Check that the client connects
 *     successfully before trying to publish. And check that the client has not disconnected
 *     before trying to publish.</td>
 * </tr>
 * <tr>
 *   <td>1500</td>
 *   <td>Unable to Publish. This can be caused by the following:
 *
 *   <ul>
 *     <li>The client's token does not have the role set to publish or
 *     moderator. Once the client has connected to the session, the <code>capabilities</code>
 *     property of the Session object lists the client's capabilities.</li>
 *
 *     <li>The end-user has denied access to the camera or microphone.</li>
 *
 *     <li>The page is loaded on HTTP in Chrome, which requires HTTPS to access the camera and
 *     microphone.</li>
 *   </ul>
 * </td>
 * </tr>
 * <tr>
 *   <td>1553</td>
 *   <td>WebRTC ICE workflow error. This is an error that occurs when trying to establish
 *   communication between clients in the session. Try publishing again or reconnecting to
 *   the session.</td>
 * </tr>
 * <tr>
 *   <td>1601</td>
 *   <td>Internal error -- WebRTC publisher error. Try republishing or reconnecting to the
 *     session.</td>
 * </tr>
 * <tr>
 *   <td>2001</td>
 *   <td>Publish Failed. Unexpected response from the OpenTok server. Try publishing again
 *     later.</td>
 * </tr>
 * </tbody></table>
 *
 * <p>Errors when calling <code>Session.signal()</code>:</p>
 *
 * <table class="docs_table" style="width:100%"><tbody>
 * <tr>
 *   <td><b><code>code</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *     <td>400</td>
 *     <td>One of the signal properties &mdash; data, type, or to &mdash;
 *                 is invalid. Or the data cannot be parsed as JSON.</td>
 * </tr>
 * <tr>
 *   <td>404</td> <td>The to connection does not exist.</td>
 * </tr>
 * <tr>
 *   <td>413</td> <td>The type string exceeds the maximum length (128 bytes),
 *     or the data string exceeds the maximum size (8 kB).</td>
 * </tr>
 * <tr>
 *   <td>500</td>
 *   <td>The client is not connected to the OpenTok session. Check that the client connects
 *     successfully before trying to signal. And check that the client has not disconnected before
 *     trying to signal.</td>
 * </tr>
 * <tr>
 *   <td>2001</td>
 *   <td>Signal Failed. Unexpected response from the OpenTok server. Try sending the signal again
 *     later.</td>
 * </tr>
 * </tbody></table>
 *
 * <p>Errors when calling <code>Session.subscribe()</code>:</p>
 *
 * <table class="docs_table" style="width:100%"><tbody>
 * <tr>
 *   <td><b>
 *       <code>code</code>
 *     </b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td>1013</td>
 *   <td>WebRTC PeerConnection error. Try resubscribing to the stream or
 *     reconnecting to the session.</td>
 * </tr>
 * <tr>
 *   <td>1554</td>
 *   <td>WebRTC ICE workflow error. This is an error that occurs when trying to establish
 *   communication between clients in the session. Try resubscribing to the stream or
 *   reconnecting to the session.</td>
 * </tr>
 * <tr>
 *   <td>1600</td>
 *   <td>Internal error -- WebRTC subscriber error. Try resubscribing to the stream or
 *     reconnecting to the session.</td>
 * </tr>
 * <tr>
 *  <td>1605</td>
 *  <td>Stream limit exceeded. The client tried to subscribe to a stream in a session
 *  that has exceeded the limit for simultaneous streams.</td>
 * </tr>
 * <tr>
 *   <td>2001</td>
 *   <td>Subscribe Failed. Unexpected response from the OpenTok server. Try subscribing again
 *     later.</td>
 * </tr>
 * </tbody></table>
 *
 * <p>Errors when calling <code>OT.initPublisher()</code>:</p>
 *
 * <table class="docs_table"><tbody>
 * <tr>
 *   <td><b><code>code</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td>1004</td>
 *   <td>Authentication error. Check the error message for details. This error can result if you
 *     pass in an expired token when trying to connect to a session. It can also occur if you
 *     pass in an invalid token or API key. Make sure that you are generating the token using
 *     the current version of one of the
 *     <a href="http://tokbox.com/opentok/libraries/server">OpenTok server SDKs</a>.</td>
 * </tr>
 * <tr>
 *   <td>1550</td>
 *   <td>Screen sharing is not supported (and you set the <code>videoSource</code> property
 *     of the <code>options</code> parameter of <code>OT.initPublisher()</code> to
 *     <code>"application"</code>, <code>"screen"</code>, or <code>"window"</code>).
 *     Before calling <code>OT.initPublisher()</code>, you can call
 *     <a href="OT.html#checkScreenSharingCapability">OT.checkScreenSharingCapability()</a>
 *     to check if screen sharing is supported.</td>
 * </tr>
 * <tr>
 *   <td>1551</td>
 *   <td>A screen-sharing extension needs to be registered but it is not. This error can occur
 *     when you set the <code>videoSource</code> property of the <code>options</code> parameter
 *     of <code>OT.initPublisher()</code> to <code>"application"</code>, <code>"screen"</code>,
 *     or <code>"window"</code>. Before calling <code>OT.initPublisher()</code>, you can call
 *     <a href="OT.html#checkScreenSharingCapability">OT.checkScreenSharingCapability()</a>
 *     to check if screen sharing requires an extension to be registered.</td>
 * </tr>
 * <tr>
 *   <td>1552</td>
 *   <td>A screen-sharing extension is required, but it is not installed. This error can occur
 *     when you set the <code>videoSource</code> property of the <code>options</code> parameter
 *     of <code>OT.initPublisher()</code> to <code>"screen"</code>. Before calling
 *     <code>OT.initPublisher()</code>, you can call
 *     <a href="OT.html#checkScreenSharingCapability">OT.checkScreenSharingCapability()</a>
 *     to check if screen sharing requires an extension to be installed.</td>
 * </tr>
 * </tbody></table>
 *
 * <p>Errors when calling <code>OT.reportIssue()</code>:</p>
 *
 * <table class="docs_table"><tbody>
 * <tr>
 *   <td><b><code>code</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td>2011</td>
 *   <td>Error calling OT.reportIssue(). Check the client's network connection.</td>
 * </tr>
 * </tbody></table>
 *
 * <p>Errors when calling <code>OT.setProxyUrl()</code>:</p>
 *
 * <table class="docs_table"><tbody>
 * <tr>
 *   <td><b><code>code</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td>
 *     2021
 *   </td>
 *   <td>
 *     Attempt to set the proxy URL after initiating a Session or Publisher object.
 *  </td>
 * </tr>
 * <tr>
 *   <td>
 *     2022
 *   </td>
 *   <td>
 *     Attempt to set the proxy URL after it has already been set (by a previous
 *     call to <code>OT.setProxyUrl()</code>).
 *  </td>
 * </tr>
 * </tbody></table>
 *
 * <p>Errors when calling <code>Publisher.setVideoSource()</code>:</p>
 *
 * <table class="docs_table"><tbody>
 * <tr>
 *   <td><b><code>code</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td>
 *     1040
 *   </td>
 *   <td>
 *    Trying to change the video source for a publisher does not use a camera input.
 *  </td>
 * </tr>
 * <tr>
 *   <td>
 *     1041
 *   </td>
 *   <td>
 *    The <code>videoSourceId</code> parameter is not a string
 *    or the device ID for a valid video input device.
 *  </td>
 * </tr>
 * </tbody></table>
 *
 * <p>General errors that can occur when calling any method:</p>
 *
 * <table class="docs_table" style="width:100%"><tbody>
 * <tr>
 *   <td><b><code>code</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td>1011</td>
 *   <td>Invalid Parameter. Check that you have passed valid parameter values into the method
 *     call.</td>
 * </tr>
 * <tr>
 *   <td>2000</td>
 *   <td>Internal Error. Try reconnecting to the OpenTok session and trying the action again.</td>
 * </tr>
 * </tbody></table>
 *
 * @property {String} message The message string provides details about the error.
 *
 * <p>
 * Use the <code>name</code> property (not the <code>message</code> property) to identify the error
 * programatically.
 *
 * @property {String} name A string identifying the error. Use this property (not the
 * <code>code</code> property) to identify the error.
 *
 * <p>Note that for all errors, this string starts with <code>'OT_'</code>.
 *
 * <p>
 *   The <code>name</code> property of the Error object can be set to one of the values
 *   listed below. For unexpected errors, this property can be undefined or set to other
 *   values. You may want to report this value when discussing an issue with TokBox support.
 * </p>
 *
 * <p>Errors that can occur when calling <code>OT.initPublisher()</code>:</p>
 *
 * <table class="docs_table" style="width:100%"><tbody>
 * <tr>
 *   <td><b><code>name</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td><code>'OT_HARDWARE_UNAVAILABLE'</code></td>
 *   <td>The hardware to fulfill the user media request appears to exist but could not be acquired.
 *   It might be in use by another application.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_INVALID_PARAMETER'</code></td>
 *   <td>One or more parameters was not valid or not provided.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_MEDIA_ERR_ABORTED'</code></td>
 *   <td>The fetching of the stream for the video element has been aborted.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_MEDIA_ERR_DECODE'</code></td>
 *   <td>A decoding error occurred while trying to play the stream in the video element.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_MEDIA_ERR_NETWORK'</code></td>
 *   <td>A network error caused the stream to stop being fetched.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_MEDIA_ERR_SRC_NOT_SUPPORTED'</code></td>
 *   <td>The stream has been detected to be not suitable for playback.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_NOT_SUPPORTED'</code></td>
 *   <td>Something in the user media request is not supported.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_NO_DEVICES_FOUND'</code></td>
 *   <td>No devices were found to provide the media stream.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_NO_VALID_CONSTRAINTS'</code></td>
 *   <td>Video and audio were both disabled. You need to enable at least one.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_SCREEN_SHARING_NOT_SUPPORTED'</code></td>
 *   <td>Screen sharing is not supported in the browser.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED'</code></td>
 *   <td>Screen-sharing support in this browser requires an extension, but one has not been
 *   registered.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED'</code></td>
 *   <td>Screen-sharing support in this browser requires an extension, but the extension is not
 *   installed.</td>
 * </tr>
 * </table>
 *
 * <p>Errors that can occur when calling <code>OT.reportIssue()</code>:</p>
 *
 * <table class="docs_table" style="width:100%"><tbody>
 * <tr>
 *   <td><b><code>name</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td><code>'OT_REPORT_ISSUE_FAILED'</code></td>
 *   <td>Error calling OT.reportIssue(). Check the client\'s network connection.</td>
 * </tr>
 * </table>
 *
 * <p>Errors that can occur when calling the <code>OT.setProxyUrl()</code> method:</p>
 *
 * <table class="docs_table" style="width:100%"><tbody>
 * <tr>
 *   <td><b><code>name</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td>
 *     <code>'SET_PROXY_URL_TIMING_ERROR'</code>
 *   </td>
 *   <td>
 *     Attempt to set the proxy URL after initiating a Session or Publisher object.
 *  </td>
 * </tr>
 * <tr>
 *   <td>
 *     <code>'PROXY_URL_ALREADY_SET_ERROR'</code>
 *   </td>
 *   <td>
 *     Attempt to set the proxy URL after it has already been set (by a previous
 *     call to <code>OT.setProxyUrl()</code>).
 *  </td>
 * </tr>
 * </table>
 *
 * <p>Errors that can occur when calling <code>Publisher.setVideoSource()</code>:</p>
 *
 * <table class="docs_table" style="width:100%"><tbody>
 * <tr>
 *   <td><b><code>name</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td><code>'OT_INVALID_VIDEO_SOURCE'</code></td>
 *   <td>The <code>videoSourceId</code> parameter is not a string
 *   or the device ID for a valid video input device.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_SET_VIDEO_SOURCE_FAILURE'</code></td>
 *   <td>Trying to change the video source for a publisher does not use
 *   a camera input.</td>
 * </tr>
 * </table>
 *
 * <p>Errors that can occur when calling <code>Session.connect()</code>:</p>
 *
 * <table class="docs_table" style="width:100%"><tbody>
 * <tr>
 *   <td><b><code>name</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td><code>'OT_AUTHENTICATION_ERROR'</code></td>
 *   <td>The API key does not match the token or session.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_BADLY_FORMED_RESPONSE'</code></td>
 *   <td>The JSON response from the OpenTok server was badly formed.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_CONNECT_FAILED'</code></td>
 *   <td>Failed to connect to session.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_CONNECTION_LIMIT_EXCEEDED'</code></td>
 *   <td>The client tried to connect to a session that has exceeded the limit for
 *   simultaneous connections.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_EMPTY_RESPONSE_BODY'</code></td>
 *   <td>Received an unexpected empty response from the OpenTok server.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_INVALID_SESSION_ID'</code></td>
 *   <td>The session ID was invalid.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_INVALID_PARAMETER'</code></td>
 *   <td>One or more parameters was not valid or not provided.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_NOT_CONNECTED'</code></td>
 *   <td>Couldn't perform action because you are not connected to the network.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_TERMS_OF_SERVICE_FAILURE'</code></td>
 *   <td>Couldn't connect due to a terms of service violation.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_INVALID_HTTP_STATUS'</code></td>
 *   <td>Received an unexpected HTTP status.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_XDOMAIN_OR_PARSING_ERROR'</code></td>
 *   <td>There was a cross domain error or the server responded with invalid JSON.</td>
 * </tr>
 * </table>
 *
 * <p>Errors that can occur when calling <code>Session.forceDisconnect()</code>:</p>
 *
 * <table class="docs_table" style="width:100%"><tbody>
 * <tr>
 *   <td><b><code>name</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td><code>'OT_INVALID_PARAMETER'</code></td>
 *   <td>One or more parameters was not valid or not provided.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_NOT_CONNECTED'</code></td>
 *   <td>Couldn't perform action because you are not connected to the network.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_PERMISSION_DENIED'</code></td>
 *   <td>This token does not allow this action. The role must be at least moderator.</td>
 * </tr>
 * </table>
 *
 * <p>Errors that can occur when calling <code>Session.forceMuteAll()</code>:</p>
 *
 * <table class="docs_table" style="width:100%"><tbody>
 * <tr>
 *   <td><b><code>name</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td><code>'OT_INVALID_PARAMETER'</code></td>
 *   <td>One or more parameters was not valid or not provided.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_NOT_CONNECTED'</code></td>
 *   <td>Couldn't perform action because you are not connected to the network.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_PERMISSION_DENIED'</code></td>
 *   <td>This token does not allow this action. The role must be at least moderator.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_UNEXPECTED_SERVER_RESPONSE'</code></td>
 *   <td>An internal server error.</li>
 * </tr>
 * </table>
 *
 * <p>Errors that can occur when calling <code>Session.forceMuteStream()</code>:</p>
 *
 * <table class="docs_table" style="width:100%"><tbody>
 * <tr>
 *   <td><b><code>name</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td><code>'OT_INVALID_PARAMETER'</code></td>
 *   <td>One or more parameters was not valid or not provided.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_NOT_CONNECTED'</code></td>
 *   <td>Couldn't perform action because you are not connected to the network.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_NOT_FOUND'</code></td>
 *   <td>The stream ID wasn't found in this session.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_PERMISSION_DENIED'</code></td>
 *   <td>The token does not allow this action. The role must be at least moderator.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_UNEXPECTED_SERVER_RESPONSE'</code></td>
 *   <td>An internal server error.</li>
 * </tr>
 * </table>
 *
 * <p>Errors that can occur when calling <code>Session.forceUnpublish()</code>:</p>
 *
 * <table class="docs_table" style="width:100%"><tbody>
 * <tr>
 *   <td><b><code>name</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td><code>'OT_INVALID_PARAMETER'</code></td>
 *   <td>One or more parameters was not valid or not provided.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_NOT_CONNECTED'</code></td>
 *   <td>Couldn't perform action because you are not connected to the network.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_PERMISSION_DENIED'</code></td>
 *   <td>This token does not allow this action. The role must be at least moderator.</td>
 * </tr>
 * </table>
 *
 * <p>Errors that can occur when calling <code>Session.publish()</code>:</p>
 *
 * <table class="docs_table" style="width:100%"><tbody>
 * <tr>
 *   <td><b><code>name</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td><code>'OT_CHROME_MICROPHONE_ACQUISITION_ERROR'</code></td>
 *   <td>Chrome fails to get access to the microphone, due to a Chrome error. To work
 *   around this issue, the end-user must restart Chrome and reload your site. This is a
 *   <a href="https://bugs.chromium.org/p/webrtc/issues/detail?id=4799">known issue</a>
 *   to be fixed in Chrome.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_CONSTRAINTS_NOT_SATISFIED'</code></td>
 *   <td>The constraints for getting user media could not be satisfied.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_CREATE_PEER_CONNECTION_FAILED'</code></td>
 *   <td>Failed to create a connection between this client and the subscriber or the
 *   OpenTok Media Router.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_HARDWARE_UNAVAILABLE'</code></td>
 *   <td>The hardware to fulfill the user media request appears to exist but could not be acquired.
 *   It might be in use by another application.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_ICE_WORKFLOW_FAILED'</code></td>
 *   <td>Something went wrong while establishing WebRTC connectivity.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_INVALID_PARAMETER'</code></td>
 *   <td>One or more parameters was not valid or not provided.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_MEDIA_ERR_ABORTED'</code></td>
 *   <td>The fetching of the stream for the video element has been aborted.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_MEDIA_ERR_DECODE'</code></td>
 *   <td>A decoding error occurred while trying to play the stream in the video element.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_MEDIA_ERR_NETWORK'</code></td>
 *   <td>A network error caused the stream to stop being fetched.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_MEDIA_ERR_SRC_NOT_SUPPORTED'</code></td>
 *   <td>The stream has been detected to be not suitable for playback.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_NO_DEVICES_FOUND'</code></td>
 *   <td>No devices were found to provide the media stream.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_NO_VALID_CONSTRAINTS'</code></td>
 *   <td>Video and audio were both disabled. You need to enable at least one.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_NOT_CONNECTED'</code></td>
 *   <td>Couldn't perform action because you are not connected to the network.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_NOT_SUPPORTED'</code></td>
 *   <td>Something in the user media request is not supported.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_PERMISSION_DENIED'</code></td>
 *   <td>This token does not allow publishing. The role must be at least publisher to enable
 *   this funcitonality.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_SCREEN_SHARING_NOT_SUPPORTED'</code></td>
 *   <td>Screen sharing is not supported in the browser.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED'</code></td>
 *   <td>Screen-sharing support in this browser requires an extension, but one has not been
 *   registered.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED'</code></td>
 *   <td>Screen-sharing support in this browser requires an extension, but the extension is not
 *   installed.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_SET_REMOTE_DESCRIPTION_FAILED'</code></td>
 *   <td>The WebRTC connection failed during setDescription.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_STREAM_CREATE_FAILED'</code></td>
 *   <td>Failed to create the stream in the server model.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_TIMEOUT'</code></td>
 *   <td>Could not publish in a reasonable amount of time.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_USER_MEDIA_ACCESS_DENIED'</code></td>
 *   <td>The end-user denied permission to access hardware devices, or the end-user
 *   denied access to the screen (for a screen-sharing stream).</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_UNEXPECTED_SERVER_RESPONSE'</code></td>
 *   <td>This indicates an unexpected error from the OpenTok server.</td>
 * </tr>
 * </table>
 *
 * <p>Errors that can occur when calling <code>Session.signal()</code>:</p>
 *
 * <table class="docs_table" style="width:100%"><tbody>
 * <tr>
 *   <td><b><code>name</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td><code>'OT_INVALID_PARAMETER'</code></td>
 *   <td>One or more parameters was not valid or not provided.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_NOT_CONNECTED'</code></td>
 *   <td>Couldn't perform action because you are not connected to the network.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_NOT_FOUND'</code></td>
 *   <td>A resource was not found.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_RATE_LIMIT_EXCEEDED'</code></td>
 *   <td>The signal could not be sent due to the rate limit.</td>
 * </tr>
 * </table>
 *
 * <p>Errors that can occur when calling <code>Session.subscribe()</code>:</p>
 *
 * <table class="docs_table" style="width:100%"><tbody>
 * <tr>
 *   <td><b><code>name</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td><code>'OT_CREATE_PEER_CONNECTION_FAILED'</code></td>
 *   <td>Failed to create a connection between this client and the stream's publisher or the
 *   OpenTok Media Router.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_DISCONNECTED'</code></td>
 *   <td>Action failed because the client is not connected to the session.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_INVALID_PARAMETER'</code></td>
 *   <td>One or more parameters was not valid or not provided.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_ICE_WORKFLOW_FAILED'</code></td>
 *   <td>Something went wrong while establishing WebRTC connectivity.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_MEDIA_ERR_ABORTED'</code></td>
 *   <td>The fetching of the stream for the video element has been aborted.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_MEDIA_ERR_DECODE'</code></td>
 *   <td>A decoding error occurred while trying to play the stream in the video element.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_MEDIA_ERR_NETWORK'</code></td>
 *   <td>A network error caused the stream to stop being fetched.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_MEDIA_ERR_SRC_NOT_SUPPORTED'</code></td>
 *   <td>The stream has been detected to be not suitable for playback.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_NOT_CONNECTED'</code></td>
 *   <td>Couldn't perform action because you are not connected to the network.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_SET_REMOTE_DESCRIPTION_FAILED'</code></td>
 *   <td>The WebRTC connection failed during setDescription.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_STREAM_DESTROYED'</code></td>
 *   <td>The stream was destroyed before it could be subscribed to.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_STREAM_LIMIT_EXCEEDED'</code></td>
 *   <td>The client tried to subscribe to a stream in a session that has exceeded the limit
 *   for simultaneous streams.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_STREAM_NOT_FOUND'</code></td>
 *   <td>Couldn't subscribe to the stream because it was not found.</td>
 * </tr>
 * </table>
 *
 * <p>Errors that can occur when calling <code>Subscriber.getStats()</code>:</p>
 *
 * <table class="docs_table" style="width:100%"><tbody>
 * <tr>
 *   <td><b><code>name</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td><code>'OT_NOT_CONNECTED'</code></td>
 *   <td>Couldn't perform action because you are not connected to the network.</td>
 * </tr>
 * </table>
 *
 * <p>Errors that can occur when the Promise returned by the <code>OT.unblockAudio()</code>
 * method is rejected:</p>
 *
 * <table class="docs_table" style="width:100%"><tbody>
 * <tr>
 *   <td><b><code>name</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td><code>'OT_MEDIA_ERR_ABORTED'</code></td>
 *   <td>The fetching of an audio stream has been aborted.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_MEDIA_ERR_NETWORK'</code></td>
 *   <td>A network error caused a stream to stop being fetched.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_MEDIA_ERR_DECODE'</code></td>
 *   <td>A decoding error occurred while trying to play a stream.</td>
 * </tr>
 * <tr>
 *   <td><code>'OT_MEDIA_ERR_SRC_NOT_SUPPORTED'</code></td>
 *   <td>A stream has been detected to be not suitable for playback.</td>
 * </tr>
 * </table>
 *
 * <p>Errors that can occur when the Promise returned by the
 * <code>Publisher.getRtcStatsReport()</code> or
 * <code>Subscriber.getRtcStatsReport()</code> method is rejected:</p>
 *
 * <table class="docs_table" style="width:100%"><tbody>
 * <tr>
 *   <td><b><code>name</code></b></td>
 *   <td><b>Description</b></td>
 * </tr>
 * <tr>
 *   <td><code>'OT_PEER_CONNECTION_NOT_CONNECTED'</code></td>
 *   <td>
 *     The <a href="https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection" target="_blank">
 *     PeerConnection</a> for the Publisher or Subscriber is not connected.
 *   </td>
 * </tr>
 * <tr>
 *   <td><code>'OT_GET_RTC_STATS_REPORT_NOT_SUPPORTED'</code></td>
 *   <td>
 *     The browser does not support this method (for example, in Chrome version 57 and lower, which
 *     does not support the <a href="https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/getStats" target="_blank">
 *     RTCPeerConnection.getStats()</a> standard).
 *   </td>
 * </tr>
 * </table>
 *
 * @class Error
 * @augments Event
 */

const OTErrorClass = function (code, message) {
  Error.call(this);

  this.code = code;
  this.message = message;
};

OTErrorClass.prototype = Object.create(Error.prototype);
OTErrorClass.prototype.constructor = OTErrorClass;

module.exports = OTErrorClass;

eventing(OTErrorClass);

/**
 * Get the title of an error by error code
 *
 * @property {Number|String} code The error code to lookup
 * @return {String} The title of the message with that code
 *
 * @example
 *
 * OTError.getTitleByCode(1006) === 'Connect Failed'
 */
OTErrorClass.getTitleByCode = function (code) {
  return errorCodeToErrorTitleMap[+code];
};

// @todo redo this when we have time to tidy up
//
// @example
//
//  OTError.handleJsException("Descriptive error message", 2000, {
//      session: session,
//      target: stream|publisher|subscriber|session|etc
//  });
//
OTErrorClass.handleJsException = ({ error, errorMsg, code, target, analytics }) => {
  if (!target) {
    throw new Error('handleJsException requires target');
  }
  const getCode = () => (code !== undefined ? code : error && error.code);
  const getMessage = () => (errorMsg !== undefined ? errorMsg : error && error.message);

  const title = OTErrorClass.getTitleByCode(getCode());

  logging.error(`OT.exception :: title: ${title} (${getCode()}) msg: ${getMessage()}`);

  try {
    const options = {
      partnerId: target.session ? target.session.apiKey : target.apiKey,
      sessionId: target.session ? target.session.sessionId : target.sessionId,
    };
    analytics.logError(getCode(), 'tb.exception', title, { details: getMessage() }, options);

    const event = new Events.ExceptionEvent(
      eventNames.EXCEPTION,
      getMessage(),
      title,
      getCode(),
      target,
      target,
      error
    );

    if (error) {
      event.stack = error.stack;
    } else {
      // Augment the event object with a stacktrace for error reporting purposes
      try {
        throw new Error();
      } catch (caughtError) {
        event.stack = caughtError.stack;
      }
    }

    OTErrorClass.dispatchEvent(event);
  } catch (err) {
    logging.error(`OT.exception :: Failed to dispatch exception - ${err.toString()}`);
    // Don't throw an error because this is asynchronous
    // don't do an exceptionHandler because that would be recursive
  }
};
