const isiPhone = userAgent => /iPhone/.test(userAgent);

// This line is for detecting iPad on iOS 13+. See https://developer.apple.com/forums/thread/119186
const isiPadOniOS13Plus = (userAgent) => {
  try {
    return userAgent.includes('Mac') && 'ontouchend' in document;
  } catch (err) {
    // Silly rabbit, you're probably running in node, huh?
    return false;
  }
};

const isiPad = userAgent =>
  /iPad/.test(userAgent) || isiPadOniOS13Plus(userAgent);

const isiOS = userAgent => isiPhone(userAgent) || isiPad(userAgent);

module.exports = { isiPhone, isiPad, isiOS };
