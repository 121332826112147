/* eslint-disable global-require */

module.exports = (deps = {}) => {
  const {
    getAudioOutputMediaDevices,
    getDefaultAudioOutputDeviceId,
    hasDevice,
  } = deps.deviceHelpers || require('../helpers/device_helpers.js')();
  const {
    getCurrentAudioOutputDeviceId,
    setCurrentAudioOutputDeviceId,
  } = deps.currentAudioOutputDevice || require('./current-audio-output-device');
  const updateSubscribersSinkId = deps.updateSubscribersSinkId
    || require('./update-subscribers-sinkid');
  const logging = deps.logging || require('../helpers/log')('currentAudioOutputDevice');

  const audioOutputDevicesChangeHandler = async () => {
    const devices = await getAudioOutputMediaDevices();

    // If there are no devices, let's store an empty value.
    if (devices.length === 0) {
      setCurrentAudioOutputDeviceId('');
      return;
    }

    const isStillConnected = hasDevice(devices, getCurrentAudioOutputDeviceId());
    if (isStillConnected) {
      // The current output device is still connected, so we don't need to update it.
      return;
    }

    // Since the current output device was removed, let's use the default device.
    const defaultDeviceId = getDefaultAudioOutputDeviceId(devices);
    setCurrentAudioOutputDeviceId(defaultDeviceId);

    // Update the current audio output device ID
    try {
      await updateSubscribersSinkId(defaultDeviceId);
    } catch (error) {
      logging.debug('Failed to set the default audio output device.', error);
    }
  };

  return audioOutputDevicesChangeHandler;
};
