// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable one-var, no-underscore-dangle, no-use-before-define, no-param-reassign */
/* eslint-disable max-len, no-var, vars-on-top, global-require */

const blacklistedStats = require('../ot/peer_connection/qos/blacklistedStats');

module.exports = (deps = {}) => {
  const isSessionProxied = deps.isSessionProxied || require('./isSessionProxied');
  const { sessionId } = deps;

  if (!isSessionProxied(sessionId)) {
    return;
  }

  blacklistedStats.forEach((stat) => {
    if (stat in deps) {
      deps[stat] = '';
    }
  });
};
