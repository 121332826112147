/*
  * Executes the provided callback before each paint (frequency is usually the monitors refresh
  * rate which is typically 60 times per second)
  *
  * @param {function()} callback
  * @constructor
  */

module.exports = function RafRunner(callback) {
  let cancelId = null;
  let running = false;

  const loop = () => {
    if (!running) { return; }
    callback();
    cancelId = window.requestAnimationFrame(loop);
  };

  this.start = () => {
    if (running) { return; }
    running = true;
    window.requestAnimationFrame(loop);
  };

  this.stop = () => {
    window.cancelAnimationFrame(cancelId);
    running = false;
  };
};
