// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-underscore-dangle, prefer-const */

const cloneDeep = require('lodash/cloneDeep');
const logging = require('../../helpers/log')('Style');

module.exports = function Style(initalStyles, onStyleChange) {
  const styleApi = this;
  const _styles = {};

  // Styles with null here means every value is valid for that style
  const _validStyleValues = {
    buttonDisplayMode: ['auto', 'mini', 'mini-auto', 'off', 'on'],
    nameDisplayMode: ['auto', 'off', 'on'],
    audioLevelDisplayMode: ['auto', 'off', 'on'],
    showSettingsButton: [true, false],
    showSpeakerButton: null,
    showMicButton: [true, false],
    backgroundImageURI: null,
    showControlBar: [true, false],
    // 'showArchiveStatus' is kept for backwards compatibility.
    // 'archiveStatusDisplayMode' is the documented style name.
    showArchiveStatus: [true, false],
    archiveStatusDisplayMode: ['auto', 'off', 'on'],
    videoDisabledDisplayMode: ['auto', 'off', 'on'],
    audioBlockedDisplayMode: ['auto', 'off', 'on'],
  };

  // Validates the style +key+ and also whether +value+ is valid for +key+
  const isValidStyle = function (key, value) {
    const valueSpec = _validStyleValues[key];

    if (valueSpec === undefined) {
      return false;
    }

    if (valueSpec === null) {
      return true;
    }

    return valueSpec.indexOf(value) !== -1;
  };

  const castValue = function (value) {
    switch (value) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return value;
    }
  };

  // Returns a copy of the styles.
  styleApi.getAll = function () {
    return cloneDeep(_styles);
  };

  styleApi.get = function (key) {
    if (key) {
      return _styles[key];
    }

    // We haven't been asked for any specific key, just return the lot
    return styleApi.getAll();
  };

  // *note:* this will not trigger onStyleChange if +silent+ is truthy
  styleApi.setAll = function (newStyles, silent) {
    Object.keys(newStyles).forEach((key) => {
      styleApi.set(key, newStyles[key], silent);
    });

    return styleApi;
  };

  // *note:* this will not trigger onStyleChange if +silent+ is truthy
  styleApi.set = function (key, value, silent) {
    logging.debug(`setStyle: ${key.toString()}`);

    let oldValue;
    const newValue = castValue(value);

    if (!isValidStyle(key, newValue)) {
      logging.warn(`Style.set::Invalid style property passed ${key} : ${newValue}`);
      return styleApi;
    }

    oldValue = _styles[key];
    if (newValue !== oldValue) {
      _styles[key] = newValue;

      if (!silent) {
        onStyleChange(key, value, oldValue);
      }
    }

    return styleApi;
  };

  if (initalStyles) {
    styleApi.setAll(initalStyles, true);
  }
};
