// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-underscore-dangle, no-param-reassign */

module.exports = function (mod) {
  const attachments = {};

  Object.keys(mod).forEach((key) => {
    attachments[key] = mod[key];
  });

  mod._attachToOTHelpers = attachments;
};
