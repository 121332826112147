const WeakMap = require('es6-weak-map');

const contexts = new WeakMap();

/**
 * This method needs to memoize fns with their context, this is
 * so that calls to off will have the same identity to the calls
 * to on.
 * @param {function} fn
 * @param {object} context
 */
function weakMemoizeBind(fn, context) {
  if (context === undefined) {
    return fn;
  }

  if (!contexts.has(context)) {
    contexts.set(context, new WeakMap());
  }

  const fnMap = contexts.get(context);

  if (!fnMap.has(fn)) {
    fnMap.set(fn, fn.bind(context));
  }

  return fnMap.get(fn);
}

module.exports = weakMemoizeBind;
