// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable one-var, no-underscore-dangle, no-use-before-define, no-param-reassign */
/* eslint-disable max-len, no-var, vars-on-top, global-require */
const sessionObjects = require('../ot/session/objects');

const { sessions } = sessionObjects;

module.exports = (sessionId) => {
  const session = sessions.get(sessionId);

  return session ? !!session._.getProxyUrl() : false;
};
