// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-underscore-dangle, no-void, no-param-reassign */
/* eslint-disable no-restricted-syntax, no-continue */

const $ = require('../elementCollection/shorthandSelector');
const env = require('../../helpers/env');
const makeEverythingAttachToOTHelpers = require('../makeEverythingAttachToOTHelpers');
const assign = require('lodash/assign');

const browserAjax = {};
module.exports = browserAjax;

function formatPostData(data) { // , contentType
  // If it's a string, we assume it's properly encoded
  if (typeof (data) === 'string') {
    return data;
  }

  const queryString = [];

  Object.keys(data).forEach((key) => {
    queryString.push(
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
    );
  });

  return queryString.join('&').replace(/\+/g, '%20');
}

if (env.name !== 'Node') {
  browserAjax.request = function (url, options, callback) {
    const request = new global.XMLHttpRequest();
    const _options = options || {};
    const _method = _options.method;

    if (!_method) {
      callback(new Error('No HTTP method specified in options'));
      return;
    }

    if (options.overrideMimeType) {
      if (request.overrideMimeType) {
        request.overrideMimeType(options.overrideMimeType);
      }
      delete options.overrideMimeType;
    }

    // Setup callbacks to correctly respond to success and error callbacks. This includes
    // interpreting the responses HTTP status, which XmlHttpRequest seems to ignore
    // by default.
    if (callback) {
      $(request).on('load', (event) => {
        const status = event.target.status;

        // We need to detect things that XMLHttpRequest considers a success,
        // but we consider to be failures.
        if (status >= 200 && (status < 300 || status === 304)) {
          callback(null, event);
        } else {
          callback(event);
        }
      });

      $(request).on('error', callback);
    }

    request.open(options.method, url, true);

    if (!_options.headers) {
      _options.headers = {};
    }

    for (const name in _options.headers) {
      if (!Object.prototype.hasOwnProperty.call(_options.headers, name)) {
        continue;
      }
      request.setRequestHeader(name, _options.headers[name]);
    }

    request.send(options.body && formatPostData(options.body));
  };

  browserAjax.get = function (url, options, callback) {
    const _options = assign(options || {}, {
      method: 'GET',
    });
    browserAjax.request(url, _options, callback);
  };

  browserAjax.post = function (url, options, callback) {
    const _options = assign(options || {}, {
      method: 'POST',
    });

    browserAjax.request(url, _options, callback);
  };

  browserAjax.getJSON = function (url, options, callback) {
    options = options || {};

    const done = function (error, event) {
      if (error) {
        callback(error, event && event.target && event.target.responseText);
      } else {
        let response;

        try {
          response = JSON.parse(event.target.responseText);
        } catch (e) {
          // Badly formed JSON
          callback(e, event && event.target && event.target.responseText);
          return;
        }

        callback(null, response, event);
      }
    };

    const extendedHeaders = assign({
      Accept: 'application/json',
    }, options.headers || {});

    browserAjax.get(url, assign(options || {}, {
      headers: extendedHeaders,
    }), done);
  };

  makeEverythingAttachToOTHelpers(browserAjax);
}
