// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, no-void, no-use-before-define, one-var */
/* eslint-disable no-restricted-syntax, no-prototype-builtins, no-underscore-dangle */

import { EventEmitter } from 'events';
import clone from 'lodash/clone';
import parseQOS from './parseQOS';
import TruthyChangeCounter from '../TruthyChangeCounter';
import now from '../../../helpers/now';
import createLogger from '../../../helpers/log';

const logging = createLogger('QoS');

class Qos extends EventEmitter {
  _peerConnection;
  _isPublisher;
  timeoutId;
  _creationTime = now();

  offerMessagesReceived = 0;
  prevStats = { timeStamp: now() };

  changeCounters = {
    videoCodec: new TruthyChangeCounter(),
    audioCodec: new TruthyChangeCounter(),
  };

  startCollecting(peerConnection, isPublisher) {
    if (!peerConnection || !peerConnection.getStats) {
      // this peerConnection doesn't support getStats, bail
      return;
    }

    this._isPublisher = isPublisher;

    const hadPeerConnection = Boolean(this._peerConnection);
    this._peerConnection = peerConnection;

    if (hadPeerConnection) {
      return;
    }

    const collectionLoop = (interval) => {
      const currentTime = now();

      // parseQOS pushes properties onto this object and gives it back
      const blankStats = {
        timeStamp: currentTime,
        duration: Math.round((currentTime - this._creationTime) / 1000),
        period: Math.round((currentTime - this.prevStats.timeStamp) / 1000),
      };

      parseQOS(
        this._peerConnection,
        this.prevStats,
        blankStats,
        this._isPublisher,
        (err, parsedStats) => {
          if (err) {
            logging.error('Failed to Parse QOS Stats:', err);
            return;
          }

          this.handleParsedStats(parsedStats);

          // Recalculate the stats
          clearTimeout(this.timeoutId);
          this.timeoutId = setTimeout(() => collectionLoop(Qos.INTERVAL), interval);
        }
      );
    };

    this.timeoutId = setTimeout(() =>
      collectionLoop(Qos.INTERVAL - Qos.INITIAL_INTERVAL), Qos.INITIAL_INTERVAL);
  }

  stopCollecting() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
    this._peerConnection = null;
  }

  handleOfferMessageReceived() {
    this.offerMessagesReceived += 1;
  }

  handleParsedStats(parsedStats) {
    Object.keys(this.changeCounters).forEach((key) => {
      this.changeCounters[key].onValue(parsedStats[key]);
      // audioCodecChangeCount, videoCodecChangeCount
      parsedStats[`${key}ChangeCount`] = this.changeCounters[key].changeCount;
    });

    parsedStats.offerMessagesReceived = this.offerMessagesReceived;

    const periodicStats = clone(parsedStats);

    // The following stats are reported with cumulative values:
    const periodicDataKeys = ['audioSentBytes', 'audioSentPackets', 'audioSentPacketsLost',
      'videoSentBytes', 'videoSentPackets', 'videoSentPacketsLost', 'audioRecvBytes',
      'audioRecvPackets', 'audioRecvPacketsLost', 'videoRecvBytes', 'videoRecvPackets',
      'videoRecvPacketsLost'];

    // This adjusts the QoS values to be periodic, rather than cumulative:
    periodicDataKeys.forEach((dataKey) => {
      periodicStats[dataKey] = this.prevStats && this.prevStats[dataKey] ?
        parsedStats[dataKey] - this.prevStats[dataKey] :
        parsedStats[dataKey];
    });

    if (this.prevStats && this.prevStats.videoFramesReceived) {
      const framesReceived = parsedStats.videoFramesReceived - this.prevStats.videoFramesReceived;
      const { period } = parsedStats;

      periodicStats.videoFrameRateReceived = Math.round(framesReceived / period);
    }

    this.prevStats = parsedStats;

    // We only need framesDecoded and framesEncoded in prevStats for calculating the video framerate
    // in Firefox 96+. So we can safely delete it from periodicStats to avoid putting it in the
    // QOS log.
    delete periodicStats.framesDecoded;
    delete periodicStats.framesEncoded;

    this.emit('stats', periodicStats);
  }
}

// Send stats after 1 sec
Qos.INITIAL_INTERVAL = 1000;
// Recalculate the stats every 30 sec
Qos.INTERVAL = 30000;

export default Qos;
