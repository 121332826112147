
const cloneDeep = require('lodash/cloneDeep');

// The backgroundImageURI of the styleHash object that we log to analytics needs to be stripped down
// because base64 data blobs are often very large and can be a privacy issue if taken from the
// user's camera.
module.exports = function styleHashLogFilter(styleHashInput) {
  const styleHash = cloneDeep(styleHashInput);

  if (styleHash.backgroundImageURI) {
    const protocol = styleHash.backgroundImageURI.split(':')[0];

    styleHash.backgroundImageURI = (
      (['http', 'https', 'data'].indexOf(protocol) !== -1) ?
        protocol :
        'other'
    );
  }

  return styleHash;
};
