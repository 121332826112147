const runtimeProperties = [
  'buildHash',
  'minimumVersion',
  'debug',
  ['websiteURL', 'websiteUrl'],
  ['cdnURL', 'cdnUrl'],
  ['loggingURL', 'loggingUrl'],
  ['apiURL', 'apiUrl'],
  ['supportSSL', true],
  ['cdnURLSSL', 'cdnUrl'],
  ['loggingURLSSL', 'loggingUrl'],
  ['apiURLSSL', 'apiUrl'],
  ['assetURL', 'assetUrl'],
];

module.exports = runtimeProperties;
