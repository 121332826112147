// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, no-void, no-underscore-dangle */

const isObject = require('lodash/isObject');

const specialDomProperties = {
  for: 'htmlFor',
  class: 'className',
};

module.exports = function (ElementCollection) {
  // Gets or sets the attribute called +name+ for the first element in the collection
  ElementCollection.prototype.attr = function (name, value) {
    if (isObject(name)) {
      let actualName;

      Object.keys(name).forEach((key) => {
        actualName = specialDomProperties[key] || key;
        this.first.setAttribute(actualName, name[key]);
      });
    } else if (value === void 0) {
      return this.first.getAttribute(specialDomProperties[name] || name);
    } else {
      this.first.setAttribute(specialDomProperties[name] || name, value);
    }

    return this;
  };

  // Removes an attribute called +name+ for the every element in the collection.
  ElementCollection.prototype.removeAttr = function (name) {
    const actualName = specialDomProperties[name] || name;

    this.forEach((element) => {
      element.removeAttribute(actualName);
    });

    return this;
  };

  // Gets, and optionally sets, the html body of the first element
  // in the collection. If the +html+ is provided then the first
  // element's html body will be replaced with it.
  //
  ElementCollection.prototype.html = function (html) {
    if (html !== void 0) {
      this.first.innerHTML = html;
    }

    return this.first.innerHTML;
  };

  // Centers +element+ within the global. You can pass through the width and height
  // if you know it, if you don't they will be calculated for you.
  ElementCollection.prototype.center = function (width, height) {
    let $element;

    this.forEach((element) => {
      $element = new ElementCollection(element);
      width = width || parseInt($element.width(), 10);
      height = height || parseInt($element.height(), 10);

      const marginLeft = `${-0.5 * width}px`;
      const marginTop = `${-0.5 * height}px`;

      $element
        .css('margin', `${marginTop} 0 0 ${marginLeft}`)
        .addClass('OT_centered');
    });

    return this;
  };

  // @remove
  // Centers +element+ within the global. You can pass through the width and height
  // if you know it, if you don't they will be calculated for you.
  ElementCollection._attachToOTHelpers.centerElement = function (element, width, height) {
    return new ElementCollection(element).center(width, height);
  };

  /**
   * Methods to calculate element widths and heights.
   */
  const _width = function (element) {
    if (element.offsetWidth > 0) {
      return `${element.offsetWidth}px`;
    }

    return new ElementCollection(element).css('width');
  };

  const _height = function (element) {
    if (element.offsetHeight > 0) {
      return `${element.offsetHeight}px`;
    }

    return new ElementCollection(element).css('height');
  };

  ElementCollection.prototype.width = function (newWidth) {
    if (newWidth) {
      this.css('width', newWidth);
      return this;
    }

    if (this.isDisplayNone()) {
      return this.makeVisibleAndYield(element => _width(element))[0];
    }

    return _width(this.get(0));
  };

  // @remove
  ElementCollection._attachToOTHelpers.width = function (element, newWidth) {
    const ret = new ElementCollection(element).width(newWidth);
    return newWidth ? ElementCollection._attachToOTHelpers : ret;
  };

  ElementCollection.prototype.height = function (newHeight) {
    if (newHeight) {
      this.css('height', newHeight);
      return this;
    }

    if (this.isDisplayNone()) {
      // We can't get the height, probably since the element is hidden.
      return this.makeVisibleAndYield(element => _height(element))[0];
    }

    return _height(this.get(0));
  };

  // @remove
  ElementCollection._attachToOTHelpers.height = function (element, newHeight) {
    const ret = new ElementCollection(element).height(newHeight);
    return newHeight ? ElementCollection._attachToOTHelpers : ret;
  };
};
