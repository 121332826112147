const trimProxyUrl = require('./trim-proxy-url');

// if proxy is defined with https://brown.fox and messaging URL with 'wss://lazy.dog'
// it updates messagingUrl to 'wss://brown.fox/lazy.dog'
module.exports = (proxyUrl, messagingUrl) => {
  if (typeof proxyUrl === 'string' && typeof messagingUrl === 'string') {
    const trimmedProxyUrl = trimProxyUrl(proxyUrl);
    const [, scheme, host] = messagingUrl.match(/(\w+:\/\/)(.*)/);
    return `${scheme}${trimmedProxyUrl}/${host}`;
  }
  return messagingUrl;
};
