module.exports = (peerConnection, activeState) => {
  const videoSender = peerConnection
    .getSenders()
    .find(({ track }) => track.kind === 'video');
  const parameters = videoSender.getParameters();
  parameters.encodings.forEach((encoding) => {
    // eslint-disable-next-line no-param-reassign
    encoding.active = activeState;
  });
  videoSender.setParameters(parameters);
};
