// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-underscore-dangle, no-param-reassign */

const Widget = require('./behaviour/widget.js');

// The "name" that we receive (e.g., from RUMOR) may actually be malicious HTML
// so we need to sanitize it before it gets rendered.
// See: OPENTOK-41141.
const sanitizeName = (name) => {
  const temp = document.createElement('div');

  temp.textContent = name;
  return temp.innerHTML;
};

// NamePanel Chrome Widget
//
// mode (String)
// Whether to display the name. Possible values are: "auto" (the name is displayed
// when the stream is first displayed and when the user mouses over the display),
// "off" (the name is not displayed), and "on" (the name is displayed).
//
// displays a name
// can be shown/hidden
// can be destroyed
module.exports = function NamePanel(options) {
  let _name = options.name;

  if (!_name || _name.trim().length === '') {
    _name = null;

    // THere's no name, just flip the mode off
    options.mode = 'off';
  }

  this.setName = function (name) {
    if (!_name) { this.setDisplayMode('auto'); }
    _name = sanitizeName(name);

    // The "name" is initially rendered as `innerHTML` when instantiated (as a
    // `Widget`).  That's why we don't use `textContent` here, instead.
    this.domElement.innerHTML = _name;
  }.bind(this);

  _name = sanitizeName(_name);

  // Mixin common widget behaviour
  Widget(this, {
    mode: options.mode,
    nodeName: 'h1',
    htmlContent: _name,
    htmlAttributes: {
      className: 'OT_name OT_edge-bar-item',
    },
  });
};
