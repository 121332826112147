const Errors = require('../ot/Errors');
const ExceptionCodes = require('../ot/exception_codes');
const otError = require('./otError')();

module.exports = (error) => {
  // @todo Can we provide more specific errors for these codes? Are these still the only
  // codes that we expect?
  const expectedErrorCodes = [403, 404, 409];

  return expectedErrorCodes.includes(parseInt(error.code, 10)) ?
    otError(Errors.STREAM_CREATE_FAILED,
      new Error(`Failed to create stream in server model: ${error.message}`),
      ExceptionCodes.UNABLE_TO_PUBLISH
    ) :
    otError(
      Errors.UNEXPECTED_SERVER_RESPONSE,
      new Error(`Unexpected server response: ${error.message}`),
      ExceptionCodes.UNEXPECTED_SERVER_RESPONSE
    );
};
