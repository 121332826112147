/* eslint-disable no-underscore-dangle */
const EventEmitter = require('events');
const defaultGenerateKey = require('./generateKey');

class KeyStore extends EventEmitter {
  constructor(deps = {}) {
    super();
    this._generateKey = deps.generateKey || defaultGenerateKey;
  }

  async set(sessionId, secret) {
    this.keyPromise = this._generateKey(sessionId, secret);
    const key = await this.keyPromise;
    this.emit('keyChanged', key);
  }

  async get() {
    const key = await this.keyPromise;
    return key;
  }

  clear() {
    this.keyPromise = undefined;
  }
}

module.exports = KeyStore;
