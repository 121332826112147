// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, no-bitwise */

const hasClassListCapability = require('../../../helpers/hasClassListCapability.js');

function hasClass(element, className) {
  if (!className) {
    return false;
  }

  if (hasClassListCapability()) {
    return element.classList.contains(className);
  }

  return element.className.indexOf(className) > -1;
}

function toggleClasses(element, classNames) {
  if (!classNames || classNames.length === 0) {
    return;
  }

  // Only bother targeting Element nodes, ignore Text Nodes, CDATA, etc
  if (element.nodeType !== 1) {
    return;
  }

  const numClasses = classNames.length;
  let i = 0;

  if (hasClassListCapability()) {
    for (; i < numClasses; ++i) {
      element.classList.toggle(classNames[i]);
    }

    return;
  }

  let className = (` ${element.className} `).replace(/[\s+]/, ' ');

  for (; i < numClasses; ++i) {
    if (hasClass(element, classNames[i])) {
      className = className.replace(` ${classNames[i]} `, ' ');
    } else {
      className += `${classNames[i]} `;
    }
  }

  element.className = className.trim();
}

function addClass(element, classNames) {
  if (!classNames || classNames.length === 0) {
    return;
  }

  // Only bother targeting Element nodes, ignore Text Nodes, CDATA, etc
  if (element.nodeType !== 1) {
    return;
  }

  const numClasses = classNames.length;
  let i = 0;

  if (hasClassListCapability()) {
    for (; i < numClasses; ++i) {
      element.classList.add(classNames[i]);
    }

    return;
  }

  // Here's our fallback to browsers that don't support element.classList

  if (!element.className && classNames.length === 1) {
    element.className = classNames.join(' ');
  } else {
    let setClass = ` ${element.className} `;

    for (; i < numClasses; ++i) {
      if (!~setClass.indexOf(` ${classNames[i]} `)) {
        setClass += `${classNames[i]} `;
      }
    }

    element.className = setClass.trim();
  }
}

function removeClass(element, classNames) {
  if (!classNames || classNames.length === 0) {
    return;
  }

  // Only bother targeting Element nodes, ignore Text Nodes, CDATA, etc
  if (element.nodeType !== 1) {
    return;
  }

  const numClasses = classNames.length;
  let i = 0;

  if (hasClassListCapability()) {
    for (; i < numClasses; ++i) {
      element.classList.remove(classNames[i]);
    }

    return;
  }

  let className = (` ${element.className} `).replace(/[\s+]/, ' ');

  for (; i < numClasses; ++i) {
    className = className.replace(` ${classNames[i]} `, ' ');
  }

  element.className = className.trim();
}

module.exports = function (ElementCollection) {
  ElementCollection.prototype.addClass = function (value) {
    if (value) {
      const classNames = value.trim().split(/\s+/);

      this.forEach((element) => {
        addClass(element, classNames);
      });
    }

    return this;
  };

  ElementCollection.prototype.removeClass = function (value) {
    if (value) {
      const classNames = value.trim().split(/\s+/);

      this.forEach((element) => {
        removeClass(element, classNames);
      });
    }

    return this;
  };

  ElementCollection.prototype.toggleClass = function (value) {
    if (value) {
      const classNames = value.trim().split(/\s+/);

      this.forEach((element) => {
        toggleClasses(element, classNames);
      });
    }

    return this;
  };

  ElementCollection.prototype.hasClass = function (value) {
    return this.some(element => hasClass(element, value));
  };
};
