const shouldUsePlanBSDP = require('../../helpers/shouldUsePlanBSDP.js');

const testSupportVideo = (remoteDesc, p2p, env, RTCPeerConnection, RTCSessionDescription) => {
  if (!RTCPeerConnection) {
    return Promise.resolve(true);
  }

  const pcConfig = {
    iceServers: [],
  };
  if (shouldUsePlanBSDP()) {
    pcConfig.sdpSemantics = 'plan-b';
  }
  const testPc = new RTCPeerConnection(pcConfig);

  const cleanup = () => {
    try { testPc.close(); } catch (e) {} // eslint-disable-line no-empty
  };

  return testPc.setRemoteDescription(new RTCSessionDescription(remoteDesc))
    .then(() => {
      cleanup();
    })
    .catch((err) => {
      cleanup();
      throw err;
    });
};

module.exports = testSupportVideo;
