// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-underscore-dangle */

import uuid from 'uuid';

import logEvents from '../logEvents';
import RumorMessage from './RumorMessage';
import * as RumorMessageTypes from './RumorMessageTypes';
import SocketError from './SocketError';
import { messages as socketCloseMessages, codes as socketCloseCodes } from '../socketCloseCodes';

export default ({
  EventEmitter,
  ReconnectableSocket,
  logging,
  allocateId,
}) => class RumorSocket extends EventEmitter {
  static CONNECTING = ReconnectableSocket.CONNECTING;
  static OPEN = ReconnectableSocket.OPEN;
  static CLOSING = ReconnectableSocket.CLOSING;
  static CLOSED = ReconnectableSocket.CLOSED;

  url;
  id;

  _opened = false;
  _socket;
  _reconnectAttempts = 0;
  _socketID = uuid();
  _receivedTransactionIDs = [];
  _notifyDisconnectAddress;
  _enableReconnection;

  get readyState() {
    return (this._socket ?
      this._socket.readyState :
      ReconnectableSocket.CONNECTING
    );
  }

  get reconnecting() { return this._opened && this._socket && this._socket.reconnecting; }

  // Messages which have not yet been acknowledged
  _pendingMessages = [];

  _logger = logging(`RumorSocket:${allocateId()}`);

  constructor({
    messagingURL,
    notifyDisconnectAddress,
    connectionId,
    enableReconnection,
    pingThreshold = 2000,
    disconnectThreshold = ((enableReconnection ? 3 : 25) * pingThreshold) - 100,
    reconnectMaxDuration = enableReconnection ? 60000 : 0,
  }) {
    super();

    logEvents({
      logger: this._logger,
      obj: this,
      eventNames: [
        'open',
        'message',
        'error',
        'reconnecting',
        'reconnectAttempt',
        'reconnectFailure',
        'reconnected',
        'close',
      ],
    });

    this.url = messagingURL;
    this.id = connectionId;

    this._notifyDisconnectAddress = notifyDisconnectAddress;
    this._enableReconnection = enableReconnection;

    this.on('error', (socketError) => {
      this._logger.error(socketError.message);
    });

    try {
      this._socket = new ReconnectableSocket({
        url: () => (!this._enableReconnection ? this.url : [
          this.url,
          this.url.indexOf('?') >= 0 ? '&' : '?',
          `socketId=${this._socketID}`,
          this._socket && this._socket.reconnecting ? '&reconnect=true' : '',
          `&attempt=${uuid()}`,
        ].join('')),
        pingThreshold,
        disconnectThreshold,
        reconnectMaxDuration,
      });
    } catch (e) {
      this._logger.error(e);
      this.emit('error', new SocketError(socketCloseCodes.CLOSE_CONNECT_EXCEPTION));
    }

    const onOpen = () => {
      this._opened = true;
      this._socket.send(RumorMessage.Connect(this.id, notifyDisconnectAddress).serialize());
      this.emit('open', this.id);
    };

    this._socket.once('open', onOpen);

    this._socket.on('message', event => this._receiveMessage(event));

    this._socket.once('close', (closeEvent) => {
      this._logger.debug(`ReconnectableSocket closed (code: ${closeEvent.code})`);

      this._clearPendingMessages();

      // We don't emit an error for these close codes because they are normal and TIMEOUT has a
      // dedicated error and we would duplicate it here.
      const { CLOSE_NORMAL, CLOSE_GOING_AWAY, CLOSE_TIMEOUT } = socketCloseCodes;

      if ([CLOSE_NORMAL, CLOSE_GOING_AWAY, CLOSE_TIMEOUT].indexOf(closeEvent.code) === -1) {
        this.emit('error', new SocketError(
          closeEvent.code,
          closeEvent.reason || closeEvent.message
        ));
      }

      this.emit('close', closeEvent);
    });

    this._socket.on('error', ({ code }) => this.emit('error', new SocketError(code)));

    this._socket.on('needsPing', () => {
      this._socket.send(RumorMessage.Ping().serialize());
    });

    this._socket.on('reconnecting', () => {
      if (!this._opened) { return; } // ignore reconnect events when we haven't opened yet
      this._clearPendingMessagesWhichShouldntRetry();
      this.emit('reconnecting');
      this._reconnectAttempts = 0;
    });

    this._socket.on('reconnectAttempt', () => {
      if (!this._opened) { return; } // ignore reconnect events when we haven't opened yet
      this._reconnectAttempts += 1;

      // TODO: Although this should be ok if the socket is already closed since ReconnectableSocket
      // will just drop it due to retryAfterReconnect=false, it should be tested.
      this._socket.send(RumorMessage.Disconnect('1').serialize(), false);

      this.emit('reconnectAttempt');
    });

    this._socket.on('reconnected', () => {
      if (!this._opened) {
        onOpen();
        return;
      } // ignore reconnect events when we haven't opened yet
      // Resend pending messages (unacknowledged messages)
      this._pendingMessages.forEach((msg) => {
        this._socket.send(msg.rumorMessage.serialize());
      });

      this.emit('reconnected');
    });

    this._socket.on('reconnectFailure', () => {
      if (!this._opened) { return; } // ignore reconnect events when we haven't opened yet
      this._logger.debug('Reconnecting failed as connectivity was not restored within ' +
        `${reconnectMaxDuration}ms`);

      // TODO: This was picked when porting over the old code, but we should be able to set use a
      // better code and message here.
      this.emit('reconnectFailure', new SocketError());
    });
  }

  _clearPendingMessagesWhichShouldntRetry() {
    const messagesToClear = this._pendingMessages.filter(message => !message.retryAfterReconnect);

    this._pendingMessages = this._pendingMessages.filter(message => message.retryAfterReconnect);

    messagesToClear.forEach((message) => {
      const error = new Error('Not connected.');
      error.code = 500;
      message.completion(error);
    });
  }

  _clearPendingMessages() {
    this._pendingMessages.forEach((message) => {
      const error = new Error('Not connected.');
      error.code = 500;
      message.completion(error);
    });

    this._pendingMessages = [];
  }

  _sendAck(msg) {
    this._socket.send(RumorMessage.Status([msg.fromAddress], {
      'TRANSACTION-ID': msg.headers['TRANSACTION-ID'],
      'X-TB-FROM-ADDRESS': this.id,
    }).serialize());
  }

  _receiveMessage(messageEvent) {
    const msg = RumorMessage.deserialize(messageEvent.data);

    if (msg.type === RumorMessageTypes.PONG) {
      return;
    }

    this._logger.debug('Received:', msg);

    if (msg.transactionId) {
      // remove pending message
      this._pendingMessages = this._pendingMessages.filter((pendingMessage) => {
        if (pendingMessage.rumorMessage.transactionId === msg.transactionId) {
          this._logger.debug('Marking', msg.transactionId, ' as received');
          pendingMessage.completion(undefined, msg);
        }

        return pendingMessage.rumorMessage.transactionId !== msg.transactionId;
      });
    }

    if (msg.transactionId && msg.type !== RumorMessageTypes.STATUS) {
      // 1) ack it!
      this._sendAck(msg);

      // Have we seen this transaction before?
      if (this._receivedTransactionIDs.indexOf(msg.transactionId) >= 0) {
        // We've handled this transactionId before, but the ACK
        // must have been lost. That's ok, we've told the server
        // so we can just ignore this message now.
        return;
      }

      this._receivedTransactionIDs.push(msg.transactionId);
    }

    this.emit('message', msg);
  }

  publish(topics, message, headers, retryAfterReconnect, completion = () => {}) {
    // TODO: Instead of completion, sending a message should return an event emitter which emits:
    // - sent: if connected, emitted on next tick, if reconnecting, emitted on reconnected
    // - ack: received ack for this message from server
    // - error: won't receive ack (not connected / dropped)
    // Also should have .status obtained from ReconnectableSocket.send

    const rumorMessage = RumorMessage.Publish(topics, message, headers);

    // We always use retryAfterReconnect=false on ReconnectableSocket because rumor does additional
    // retries based on the ack status of messages which ReconnectableSocket doesn't know about,
    // and we don't want these retry strategies to conflict.
    const status = this._socket.send(rumorMessage.serialize(), false);

    if (status === 'dropped' && !retryAfterReconnect) {
      const error = new Error('Not connected.');
      error.code = 500;
      completion(error);
      return;
    }

    this._pendingMessages.push({
      rumorMessage,
      retryAfterReconnect,
      completion,
    });
  }

  subscribe(topics) {
    this._socket.send(RumorMessage.Subscribe(topics).serialize());
  }

  unsubscribe(topics) {
    this._socket.send(RumorMessage.Unsubscribe(topics).serialize());
  }

  disconnect() {
    if (this.readyState === RumorSocket.OPEN) {
      this._socket.send(RumorMessage.Disconnect().serialize());
    }

    if (
      this._socket.readyState !== ReconnectableSocket.CLOSED &&
      this._socket.readyState !== ReconnectableSocket.CLOSING
    ) {
      const { CLOSE_NORMAL } = socketCloseCodes;
      this._socket.close(CLOSE_NORMAL, socketCloseMessages[CLOSE_NORMAL]);
    }
  }

  status(toAddress, transactionId) {
    this._socket.send(RumorMessage.Status(toAddress, {
      'TRANSACTION-ID': transactionId,
      'X-TB-FROM-ADDRESS': this.id,
    }).serialize());
  }

  reconnectRetriesCount() { return this._reconnectAttempts; }
  messageQueueSize() { return this._pendingMessages.length; }

  // This could do with some reconciliation. Also with logger name now.
  get socketID() { return this._socketID; }
};
