// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, global-require */

module.exports = function getNativeEnumerateDevicesFactory(deps = {}) {
  const windowMock = deps.global || global;

  return function getNativeEnumerateDevices() {
    if (windowMock.navigator.mediaDevices) {
      const { mediaDevices } = windowMock.navigator;
      return mediaDevices.enumerateDevices && mediaDevices.enumerateDevices.bind(mediaDevices);
    }

    // @todo perhaps we should throw here?
    return undefined;
  };
};
