/*
 * Installs a watcher that will call the given callback if the audio from a subscriber is
 arriving, but the output is having issues.
 *
 * It polls getStats every 500 ms to find
 * 1) any sign of audio bytes received, but no change in audioLevel, and totalSamplesReceived or
 * 2) any sign of audio bytes received, but audioLevel is 0, and totalSamplesReceived is increasing
 * If it finds the issue,
 * it signals the anomaly by executing the given function.
 *
 * @param {function(cb: function(DOMError, Array<RTCStats>))} getStats
 *    the function to call to get the stats
 * @param {function} warningCb to be called when a potential problem is detected
 * @param {function} disableAudioLevelStuckAt0 if true, it won't verify audioLevelStuckAt0 scenario
 * @returns {function} cancel the watch
 */
const AUDIO_STUCK_AT_0_WARNING_TIMEOUT = 3000;

module.exports = function watchSubscriberAudio(
  getStats, warningCb, disableAudioLevelStuckAt0 = false) {
  let lastAudioLevel = null;
  let lastTotalSamplesReceived = 0;
  let inboundAudioReceiverTrackId = null;
  let isAudioLevelStuckAt0Disabled = disableAudioLevelStuckAt0;

  const isInboundAudioRTPStream = rtcStats =>
    rtcStats.type === 'inbound-rtp' &&
    ((rtcStats.kind || rtcStats.mediaType) === 'audio');

  const isInboundAudioReceiver = rtcStats =>
    rtcStats.id === inboundAudioReceiverTrackId &&
    rtcStats.type === 'track' &&
    ((rtcStats.kind || rtcStats.mediaType) === 'audio');

  const isAudioLevelStuck = (audioLevel, totalSamplesReceived) =>
    audioLevel === lastAudioLevel &&
    totalSamplesReceived === lastTotalSamplesReceived;

  const isAudioLevelStuckAt0 = (audioLevel, totalSamplesReceived) =>
    !isAudioLevelStuckAt0Disabled &&
    audioLevel === 0 && lastAudioLevel === 0 &&
    totalSamplesReceived > lastTotalSamplesReceived;

  const initAudioLevelStuckAt0Timer = () => {
    setTimeout(() => {
      isAudioLevelStuckAt0Disabled = true;
    }, AUDIO_STUCK_AT_0_WARNING_TIMEOUT);
  };

  const watcherInterval = setInterval(() => {
    getStats((error, rtcStatsItems) => {
      if (error) {
        return;
      }
      const inboundAudioRtcStreamStat = rtcStatsItems.find(isInboundAudioRTPStream) || {};
      inboundAudioReceiverTrackId = inboundAudioRtcStreamStat.trackId;
      const inboundAudioReceiverStat = rtcStatsItems.find(isInboundAudioReceiver);

      if (inboundAudioReceiverStat) {
        const { audioLevel, totalSamplesReceived } = inboundAudioReceiverStat;
        if (audioLevel === undefined) {
          // Let's ignore this stats in case audioLevel is undefined
          return;
        }
        if (isAudioLevelStuck(audioLevel, totalSamplesReceived)) {
          // OPENTOK-41097 - abnormal condition detected
          clearInterval(watcherInterval);
          warningCb('audioLevelStuck');
        } else if (isAudioLevelStuckAt0(audioLevel, totalSamplesReceived)) {
          // The audioLevel property should only go to 0 if a client is muted
          // OPENTOK-41073 - abnormal condition detected
          clearInterval(watcherInterval);
          warningCb('audioLevelStuckAt0');
        }
        lastAudioLevel = audioLevel;
        lastTotalSamplesReceived = totalSamplesReceived;
      }
    });
  }, 500);

  if (!disableAudioLevelStuckAt0) {
    initAudioLevelStuckAt0Timer();
  }

  return {
    stop: () => {
      clearInterval(watcherInterval);
    },
  };
};
