/* eslint-disable consistent-return */
const GetAudioLevelSampler = require('../../helpers/audio_level_samplers/get_audio_level_sampler');
const WebAudioAudioLevelSampler = require('../../helpers/audio_level_samplers/webaudio_audio_level_sampler');
const hasSynchronizationSourcesCapability = require('../../helpers/hasSynchronizationSourcesCapability.js');
const hasAudioOutputLevelStatCapability = require('../../helpers/hasAudioOutputLevelStatCapability.js');
const hasRemoteStreamsWithWebAudio = require('../../helpers/hasRemoteStreamsWithWebAudio.js');
const getAudioContext = require('../../helpers/audio_context.js')();
const isAudioContext = require('../../helpers/is_audio_context.js');

const logging = require('../../helpers/log')('Subscriber');

module.exports = (peerConnection, deps = {}) => {
  const hasGetSynchronizationSupport = deps.hasGetSynchronizationSupport;

  if (!peerConnection) return;

  // prefer the audioLevelSampler (more efficient and better responsiveness)
  if (hasSynchronizationSourcesCapability({ hasGetSynchronizationSupport })) {
    return new GetAudioLevelSampler(peerConnection.getSynchronizationSources);
  }

  if (hasAudioOutputLevelStatCapability()) {
    return new GetAudioLevelSampler(peerConnection.getStats);
  }

  if (hasRemoteStreamsWithWebAudio()) {
    let audioContext;
    try {
      audioContext = deps.audioContext || getAudioContext();
    } catch (e) {
      logging.warn('Failed to get AudioContext()', e);
    }

    if (isAudioContext(audioContext)) {
      return new WebAudioAudioLevelSampler(audioContext);
    }
  }
};
