const once = require('lodash/once');

const StaticConfig = require('../helpers/StaticConfig')();
const Analytics = require('../helpers/analytics');
const APIKEY = require('./api_key.js');
const EnvironmentLoader = require('../ot/environment_loader.js');
const hasOpenTokSupport = require('../helpers/hasOpenTokSupport.js').once;
const isWebSocketSupported = require('../helpers/isWebSocketSupported.js');
const OTHelpers = require('../common-js-helpers/OTHelpers.js');
const logging = require('../helpers/log')('systemRequirements');
const upgradeHtml = require('../../html/upgrade.html');
const Modal = require('../helpers/modal.js');

const staticConfig = StaticConfig.onlyLocal();

// This will be logged before we even get the correct loggingUrl from
// config.opentok.com.
const analytics = new Analytics();

const logOnce = once(() => {
  analytics.logEvent({
    action: 'checkSystemRequirements',
    variation: 'notHasRequirements',
    partnerId: APIKEY.value,
    payload: { userAgent: OTHelpers.env.userAgent },
  });
});

const logUserAgentParsingOnce = once(() => {
  require('./user-agent-parsing-logger')(); // eslint-disable-line global-require
});

let isDialogOpen = false;

module.exports = {
  /**
   * Checks if the system supports OpenTok for WebRTC. Note that this method is called
   * automatically when you call <code>OT.initPublisher()</code> or <code>OT.initSession()</code>,
   * and if the system doesn't support OpenTok, the OpenTok.js library displays a message to
   * the user. Call the <code>OT.checkSystemRequirements()</code> method before calling
   * <code>OT.initPublisher()</code> or <code>OT.initSession()</code> if you do not want the
   * library to display that message.
   *
   * @return {Number} Whether the system supports OpenTok for WebRTC (1) or not (0).
   * @see <a href="#upgradeSystemRequirements">OT.upgradeSystemRequirements()</a>
   * @method OT.checkSystemRequirements
   * @memberof OT
   */
  check() {
    logging.debug('OT.checkSystemRequirements()');

    // We use Number here to coerce a Boolean to 1 or 0
    const systemRequirementsMet = Number(isWebSocketSupported && hasOpenTokSupport());

    logUserAgentParsingOnce();
    if (!systemRequirementsMet) {
      logOnce();
    }

    return systemRequirementsMet;
  },
  /**
   * Displays information about system requirements for OpenTok for WebRTC. This
   * information is displayed in an iframe element that fills the browser window.
   * <p>
   * <i>Note:</i> this information is displayed automatically when you call the
   * <code>OT.initSession()</code> or the <code>OT.initPublisher()</code> method
   * if the client does not support OpenTok for WebRTC.
   * </p>
   * @see <a href="#checkSystemRequirements">OT.checkSystemRequirements()</a>
   * @method OT.upgradeSystemRequirements
   * @memberof OT
   */
  upgrade({
    // @todo isSupportedButOld relies upon logic outside of itself
    // basically a poor assumption is made here that if we are being called, and
    // our browser is in staticConfig.minimumVersion we must be outdated.
    // The upgrade method should gather this information itself, instead of
    // relying upon assumptions.
    isSupportedButOld = OTHelpers.env.name.toLowerCase() in staticConfig.minimumVersion,
    userAgent = navigator.userAgent,
  } = {}) {
    // trigger after the OT environment has loaded
    EnvironmentLoader.onLoad(() => {
      if (isDialogOpen) { return; }

      const modal = new Modal(upgradeHtml);
      isDialogOpen = true;

      modal.on('close', () => {
        isDialogOpen = false;
      });

      modal.open();

      let section;

      if (userAgent.match(/(iPad|iPhone|iPod|android|webOS)/i)) {
        section = `${isSupportedButOld ? 'supported-' : ''}mobile-browser`;
      } else {
        section = `${isSupportedButOld ? 'upgrade' : 'normal'}-install`;
      }

      modal.el(`#section-${section}`).style.display = 'block';
    });
  },
};
