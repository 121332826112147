const env = require('./env.js');

const hasGetSynchronizationSupport = () => window.RTCRtpReceiver && ('getSynchronizationSources' in window.RTCRtpReceiver.prototype);

// Firefox also has getSynchronizationSources implemented, but we are not
// gonna use getSynchronizationSources for audio level tracking in FF
// as the implementation on FF is not that good. Hence the additional checks
// for browser versions
module.exports = (deps = {}) => {
  const hasGetSyncSupport = deps.hasGetSynchronizationSupport || hasGetSynchronizationSupport;

  return hasGetSyncSupport() && !env.isFirefox;
};
