/*
  * Executes the provided callback thanks to <code>global.setTimeout</code>.
  *
  * @param {function()} callback
  * @param {number} frequency how many times per second we want to execute the callback
  * @param {number} maxIterations the maximum amount of iterations for this runner
  * @constructor
  */

function IntervalRunner(callback, desiredFrequency, maxIterations = Infinity) {
  let timeoutId = null;
  let running = false;
  let iteration = 1;
  const timeBetween = 1000 / desiredFrequency;

  const loop = () => {
    if (!running || iteration > maxIterations) {
      return;
    }
    iteration += 1;
    callback();
    timeoutId = setTimeout(loop, timeBetween);
  };

  this.start = () => {
    if (running) {
      return;
    }
    running = true;
    setTimeout(loop, timeBetween);
  };

  this.stop = () => {
    global.clearTimeout(timeoutId);
    running = false;
  };
}

module.exports = IntervalRunner;
