const Events = require('events');

module.exports = class Modal extends Events {
  constructor(innerHTML, { destroyOnClose = true } = {}) {
    super();
    this.modalDiv = document.createElement('div');
    this.modalDiv.classList.add('OT_ModalDialog');
    this.modalDiv.innerHTML = innerHTML;
    const closeBtnEls = this.modalDiv.querySelectorAll('[role=OT_close]');

    [].forEach.call(closeBtnEls, closeBtnEl =>
      closeBtnEl.addEventListener('click', (event) => {
        event.preventDefault();
        this.close();
      })
    );

    this.close();
    document.body.appendChild(this.modalDiv);

    if (destroyOnClose) {
      this.on('closed', () => this.destroy());
    }
  }
  open() {
    this.modalDiv.style.display = 'block';
    this.emit('opened');
  }
  close() {
    this.modalDiv.style.display = 'none';
    this.emit('closed');
  }
  destroy() {
    this.modalDiv.innerHTML = '';
    this.modalDiv.parentNode.removeChild(this.modalDiv);
    this.emit('destroyed');
  }
  el(query) {
    return this.modalDiv.querySelector(query);
  }
};
