/* eslint-disable global-require */

const once = require('lodash/once');

// Indicates whether this client needs its H264 profiles to be swapped
// Currently this is just Chrome on Android (OPENTOK-34457)
// See: https://bugs.chromium.org/p/webrtc/issues/detail?id=8584
exports.check = (deps = {}) => {
  const env = deps.env || require('../helpers/env.js');
  return (
    env.name === 'Chrome' &&
    env.userAgent.toLowerCase().indexOf('android') > -1 &&
    env.version < 65
  );
};

exports.once = once(() => exports.check());
