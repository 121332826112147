module.exports = ({ objectToTrack, keys, onUsage } = {}) => {
  if (!Array.isArray(keys) && keys !== undefined) {
    throw new Error('keys must be an array, or undefined');
  }
  if (typeof onUsage !== 'function') {
    throw new Error('onUsage must be a function');
  }
  if (objectToTrack == null) {
    throw new Error('Expected objectToTrack to be a trackable object');
  }
  Object.keys(objectToTrack).forEach((key) => {
    if (keys !== undefined && keys.indexOf(key) === -1) {
      return;
    }
    let value = objectToTrack[key];
    Object.defineProperty(objectToTrack, key, {
      get() {
        onUsage(key);
        return value;
      },
      set(newValue) {
        value = newValue;
        onUsage(key, value);
      },
      enumerable: true,
    });
  });
};
