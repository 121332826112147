// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, global-require */

const clone = require('lodash/clone');

module.exports = function generateSimpleStateMachineFactory() {
  // A Factory method for generating simple state machine classes.
  //
  // @usage
  //    var StateMachine = generateSimpleStateMachine('start', ['start', 'middle', 'end', {
  //      start: ['middle'],
  //      middle: ['end'],
  //      end: ['start']
  //    }]);
  //
  //    var states = new StateMachine();
  //    state.current;            // <-- start
  //    state.set('middle');
  //
  return function generateSimpleStateMachine(initialState, states, transitions) {
    const validStates = states.slice();
    const validTransitions = clone(transitions);

    const isValidState = function (state) {
      return validStates.indexOf(state) !== -1;
    };

    const isValidTransition = function (fromState, toState) {
      return validTransitions[fromState] &&
        validTransitions[fromState].indexOf(toState) !== -1;
    };

    return function (stateChangeFailed) {
      let currentState = initialState;
      let previousState = null;

      this.current = currentState;

      function signalChangeFailed(message, newState) {
        stateChangeFailed({
          message,
          newState,
          currentState,
          previousState,
        });
      }

      // Validates +newState+. If it's invalid it triggers stateChangeFailed and returns false.
      function handleInvalidStateChanges(newState) {
        if (!isValidState(newState)) {
          signalChangeFailed(`'${newState}' is not a valid state`, newState);

          return false;
        }

        if (!isValidTransition(currentState, newState)) {
          signalChangeFailed(`'${currentState}' cannot transition to '${
            newState}'`, newState);

          return false;
        }

        return true;
      }

      this.set = function (newState) {
        if (!handleInvalidStateChanges(newState)) { return; }
        previousState = currentState;
        this.current = newState;
        currentState = newState;
      };
    };
  };
};
