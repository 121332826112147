const {
  getActiveAudioOutputDevice,
} = require('../helpers/device_helpers.js')();

/**
 * Returns a Promise that resolves the current audio output device.
 * <p>
 *   The method returns a Promise which resolves an object that has two properties:
 *   <code>deviceId</code> and <code>label</code>, each of which are strings.
 * </p>
 * <p>
 *   The <code>deviceId</code> property is a unique ID for the device. You can pass
 *   the <code>deviceId</code> as the parameter of the <a href="#setAudioOutputDevice">
 *   OT.setAudioOutputDevice()</a> method. The <code>deviceId</code>
 *   property is set to undefined if the user has not previously granted access to
 *   a camera and microphone.
 * <p>
 * </p>
 *   The <code>label</code> property identifies the device. The <code>label</code>
 *   property is set to an empty string if the user has not previously granted access to
 *   a camera and microphone.</li>
 * </p>
 *
 * @see <a href="#getAudioOutputDevices">OT.getAudioOutputDevices()</a>
 * @see <a href="#setAudioOutputDevice">OT.setAudioOutputDevice()</a>
 * @method OT.getActiveAudioOutputDevice
 * @memberof OT
 */
module.exports = () => getActiveAudioOutputDevice();
