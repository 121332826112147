// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-underscore-dangle, no-use-before-define */

const domState = require('../helpers/domState.js');
const eventNames = require('../helpers/eventNames');
const eventing = require('../helpers/eventing');
const Events = require('./events.js')();

// Helper to synchronise several startup tasks and then dispatch a unified
// 'envLoaded' event.

function EnvironmentLoader() {
  const environmentLoader = this;
  eventing(environmentLoader);

  function isReady() {
    return (
      domState.isDomLoaded() &&
      !domState.isDomUnloaded()
    );
  }

  function onLoaded() {
    if (isReady()) {
      environmentLoader.dispatchEvent(
        new Events.EnvLoadedEvent(eventNames.ENV_LOADED)
      );
    }
  }

  function onDomReady() {
    domState.whenUnloaded.then(onDomUnload);
    onLoaded();
  }

  function onDomUnload() {
    environmentLoader.dispatchEvent(
      new Events.EnvLoadedEvent(eventNames.ENV_UNLOADED)
    );
  }

  domState.whenLoaded.then(onDomReady);

  this.onLoad = function (cb, context) {
    if (isReady()) {
      cb.call(context);
      return;
    }

    environmentLoader.on(eventNames.ENV_LOADED, cb, context);
  };

  this.onUnload = function (cb, context) {
    if (this.isUnloaded()) {
      cb.call(context);
      return;
    }

    environmentLoader.on(eventNames.ENV_UNLOADED, cb, context);
  };

  this.isUnloaded = function () {
    return domState.isDomUnloaded();
  };
}

module.exports = new EnvironmentLoader();
