const createLogger = require('./log');

const logger = createLogger('hasValidPeerConnection');

function checkPeerConnection(pc) {
  return new Promise((resolve) => {
    pc.addEventListener('signalingstatechange', () => resolve(true));
    pc.createOffer({ offerToReceiveAudio: 1 })
      .then(offer => pc.setLocalDescription(offer))
      .then(() => setTimeout(() => { resolve(false); }, 1000));
  });
}

async function hasValidPeerConnection(PeerConnection) {
  let pc;

  try {
    pc = new PeerConnection({ iceServers: [] });
  } catch (err) {
    logger.error('Error occurred creating PeerConnection', err);
    return false;
  }

  try {
    await checkPeerConnection(pc);
  } catch (err) {
    logger.error('Error occurred checking the validity of the PeerConnection', err);
    try {
      pc.close();
    } catch (e) {
      // ignore errors
    }
    return false;
  }

  try {
    pc.close();
  } catch (err) {
    logger.error('Error occurred closing PeerConnection', err);
    return false;
  }

  return true;
}

module.exports = hasValidPeerConnection;
