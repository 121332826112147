const audioOutputDevicesChangeHandler = require('./audio-output-devices-change-handler')();
const {
  hasAudioOutputApiSupport,
  getNativeMediaDevices,
} = require('../helpers/device_helpers.js')();

module.exports = () => {
  // In order to keep the current device ID synchronized, let's listen to the device change event
  if (hasAudioOutputApiSupport()) {
    const mediaDevices = getNativeMediaDevices();
    let isProcessing = false;
    mediaDevices.addEventListener('devicechange', async () => {
      if (isProcessing) {
        return;
      }
      isProcessing = true;
      await audioOutputDevicesChangeHandler();
      isProcessing = false;
    });
  }
};
