module.exports = function iOSVersion(userAgent) {
  // Make sure parser can handle versions like 15_4 and 15_4_1
  const match = userAgent.match(/OS (\d+_\d+).* like Mac/);

  if (match === null || !match[1]) {
    return undefined;
  }

  return parseFloat(match[1].replace('_', '.'));
};
