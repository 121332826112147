// Adapts a function that returns or promise or takes success and error
// callbacks, and returns a function that always returns a promise.

// This is used to provide backwards compatibility for WebRTC implementations,
// which used to use success and error callbacks, and now return promises.

module.exports = function promisify(context, methodName) {
  const method = context[methodName];

  return function (input) {
    return new Promise((resolve, reject) => {
      const result = method.apply(context, [input, resolve, reject]);

      if (result && typeof result.then === 'function') {
        resolve(result);
      }
    });
  };
};
