// Even though earlier versions of Chrome can support E2EE, let's just support
// more recent versions for now.
const isE2eeSupported = env =>
  // Hold off on supporting Chrome on iOS in the meantime
  !env.isIos &&
  env.isChrome && env.version >= 99;

module.exports = (deps = {}) => {
  /* eslint-disable-next-line global-require */
  const hasOpenTokSupport = deps.hasOpenTokSupport || require('./hasOpenTokSupport').once;

  /* eslint-disable-next-line global-require */
  const env = deps.env || require('../helpers/env');

  return hasOpenTokSupport() && isE2eeSupported(env);
};
