const otError = require('../../helpers/otError')();
const Errors = require('../Errors');
const ExceptionCodes = require('../exception_codes');

module.exports = (err) => {
  if (String(err.code) === '404') {
    return otError(
      Errors.STREAM_NOT_FOUND,
      new Error('stream not found'),
      ExceptionCodes.UNABLE_TO_SUBSCRIBE
    );
  }

  if (String(err.code) === '409' && err.message === 'streamLimitExceeded') {
    return otError(
      Errors.STREAM_LIMIT_EXCEEDED,
      new Error('The limit for concurrent streams in the session has been reached'),
      ExceptionCodes.STREAM_LIMIT_EXCEEDED
    );
  }

  return otError(
    Errors.UNABLE_TO_SUBSCRIBE,
    new Error(`subscriberCreate failed with unexpected code ${err.code}: ${err.message}`),
    ExceptionCodes.UNEXPECTED_SERVER_RESPONSE
  );
};
