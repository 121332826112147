/* global MediaStreamTrack */
const env = require('../../helpers/env');

module.exports = (videoSource) => {
  if (!env.isElectron) {
    return false;
  }

  // ElectronJS sets the label to the same value for screensharing sources,
  // regardless of the DesktopCapturer source type
  return videoSource instanceof MediaStreamTrack && videoSource.label === 'Screen';
};
