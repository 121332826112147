// We can't use ES6 classes with babel because babel invokes the super
// constructor without the new keyword. This triggers an error when used
// with RTCPeerConnection and other native classes.

const getOwnProperties = (target) => {
  const properties = {};
  Object.getOwnPropertyNames(target).forEach((key) => {
    properties[key] = Object.getOwnPropertyDescriptor(target, key);
  });
  return properties;
};

module.exports = function extendES5Native(ParentClass) {
  function ChildClass(...args) {
    const instance = new ParentClass(...args);
    Object.setPrototypeOf(instance, ChildClass.prototype);
    return instance;
  }

  ChildClass.prototype = Object.create(ParentClass.prototype);
  Object.defineProperties(ChildClass.prototype, getOwnProperties(ParentClass.prototype));

  Object.keys(ParentClass)
    .forEach((staticKey) => {
      ChildClass[staticKey] = ParentClass[staticKey];
    });

  return ChildClass;
};

