const env = require('../helpers/env.js');
const isSecureContextRequired = require('./isSecureContextRequired');

const hasGetUserMediaSupport = () => {
  // Except for Safari, iOS WebKit apps don't support GUM
  if (env.isIosWebView) {
    return false;
  }

  if (isSecureContextRequired()) {
    return true;
  }

  return (global.navigator.mediaDevices && global.navigator.mediaDevices.getUserMedia) ||
    global.navigator.webkitGetUserMedia ||
    global.navigator.mozGetUserMedia;
};

// Indicates whether this client supports the getUserMedia API.
module.exports = () => Boolean(
  env.name !== 'Node' && hasGetUserMediaSupport()
);
