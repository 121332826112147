/* eslint-disable global-require */
const AnalyticsHelper = require('../helpers/analytics');

module.exports = (deps = {}) => {
  const env = deps.env || require('../helpers/env');
  const analytics = deps.analytics || new AnalyticsHelper();

  analytics.logEvent({
    action: 'UserAgentParsing',
    variation: 'Attempt',
    payload: {
      userAgent: env.userAgent,
    },
  });

  analytics.logEvent({
    action: 'UserAgentParsing',
    variation: env.error ? 'Failure' : 'Success',
    payload: {
      userAgent: env.userAgent,
    },
  });
};
