const IntervalRunner = require('../ot/interval_runner');

module.exports = function waitUntil(predicate, { frequency = 60, timeout = 2000 } = {}) {
  return new Promise((resolve, reject) => {
    let timeoutTimerId;

    const intervalRunner = new IntervalRunner(() => {
      function tryPredicate() {
        try {
          return predicate();
        } catch (e) {
          return false;
        }
      }

      if (tryPredicate()) {
        clearTimeout(timeoutTimerId);
        intervalRunner.stop();
        resolve();
      }
    }, frequency);

    timeoutTimerId = setTimeout(() => {
      intervalRunner.stop();
      reject(new Error('TIMEOUT'));
    }, timeout);

    intervalRunner.start();
  });
};
