const assign = require('lodash/assign');
const Capabilities = require('./capabilities.js');
const eventing = require('../helpers/eventing');
const Events = require('./events.js')();
const connectionCapabilities = require('./connectionCapabilities.js');

/**
 * The Connection object represents a connection to an OpenTok session. Each client that connects
 * to a session has a unique connection, with a unique connection ID (represented by the
 * <code>id</code> property of the Connection object for the client).
 * <p>
 * The Session object has a <code>connection</code> property that is a Connection object.
 * It represents the local client's connection. (A client only has a connection once the
 * client has successfully called the <code>connect()</code> method of the {@link Session}
 * object.)
 * <p>
 * The Session object dispatches a <code>connectionCreated</code> event when each client (including
 * your own) connects to a session (and for clients that are present in the session when you
 * connect). The <code>connectionCreated</code> event object has a <code>connection</code>
 * property, which is a Connection object corresponding to the client the event pertains to.
 * <p>
 * The Stream object has a <code>connection</code> property that is a Connection object.
 * It represents the connection of the client that is publishing the stream.
 *
 * @class Connection
 * @property {String} connectionId The ID of this connection.
 * @property {Number} creationTime The timestamp for the creation of the connection. This
 * value is calculated in milliseconds.
 * You can convert this value to a Date object by calling <code>new Date(creationTime)</code>,
 * where <code>creationTime</code>
 * is the <code>creationTime</code> property of the Connection object.
 * @property {String} data A string containing metadata describing the
 * connection. When you generate a user token, you can define connection data (see the
 * <a href="https://tokbox.com/developer/guides/create-token/">Token creation overview</a>).
 */
function Connection(id, creationTime, data, capabilitiesHash, permissionsHash) {
  let destroyedReason;

  this.id = id;
  this.connectionId = id;
  this.creationTime = creationTime ? Number(creationTime) : null;
  this.data = data;
  this.capabilities = connectionCapabilities(capabilitiesHash);
  this.permissions = new Capabilities(permissionsHash);
  this.quality = null;

  eventing(this);

  this.destroy = (reason, quiet) => {
    destroyedReason = reason || 'clientDisconnected';

    if (quiet !== true) {
      this.dispatchEvent(
        new Events.DestroyedEvent(
          // This should be eventNames.CONNECTION_DESTROYED, but
          // the value of that is currently shared with Session
          'destroyed',
          this,
          destroyedReason
        )
      );
    }
  };

  this.destroyed = () => destroyedReason !== undefined;
  this.destroyedReason = () => destroyedReason;
}

Connection.fromHash = ({ id, creationTime, data, capablities, permissions }) => new Connection(
  id,
  creationTime,
  data,
  assign(capablities || {}, { supportsWebRTC: true }),
  permissions || []
);

module.exports = Connection;
