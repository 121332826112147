// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable global-require */

const createCleanupJobs = require('../../helpers/createCleanupJobs.js');
const RafRunner = require('../RafRunner');

module.exports = function createChromeMixinFactory(deps = {}) {
  const Archiving = deps.Archiving || require('../chrome/archiving.js');
  const AudioLevelMeter = deps.AudioLevelMeter || require('../chrome/audio_level_meter.js');
  const AudioLevelTransformer = deps.AudioLevelTransformer || require('../audio_level_transformer');
  const BackingBar = deps.BackingBar || require('../chrome/backing_bar.js');
  const Chrome = deps.Chrome || require('../chrome/chrome.js');
  const MuteButton = deps.MuteButton || require('../chrome/mute_button.js')();
  const NamePanel = deps.NamePanel || require('../chrome/name_panel.js');
  const StylableComponent = deps.StylableComponent || require('../styling/stylable_component.js');
  const logging = deps.logging || require('../../helpers/log')('createChromeMixin');

  return function createChromeMixin(publisher, {
    name,
    publishAudio,
    publishVideo,
    audioSource,
    showControls,
    shouldAllowAudio,
    logAnalyticsEvent,
  } = {}) {
    let audioLevelMeter;
    let chrome;
    let widgetView;

    const chromeMixin = {};
    const cleanupJobs = createCleanupJobs();

    // If mode is false, then that is the mode. If mode is true then we'll
    // definitely display  the button, but we'll defer the model to the
    // Publishers buttonDisplayMode style property.
    const chromeButtonMode = (mode) => {
      if (mode === false) { return 'off'; }

      const defaultMode = publisher.getStyle('buttonDisplayMode');

      // The default model is false, but it's overridden by +mode+ being true
      if (defaultMode === false) { return 'on'; }

      // defaultMode is either true or auto.
      return defaultMode;
    };

    let uiPromise = new Promise((resolve, reject) => {
      chromeMixin.init = (widgetViewParam) => {
        if (!publisher.getStyle('showArchiveStatus')) {
          logAnalyticsEvent('showArchiveStatus', 'createChrome', { mode: 'off' });
        }

        const widgets = {
          backingBar: new BackingBar({
            nameMode: !name ? 'off' : publisher.getStyle('nameDisplayMode'),
            muteMode: chromeButtonMode(publisher.getStyle('buttonDisplayMode')),
          }),

          name: new NamePanel({
            name,
            mode: publisher.getStyle('nameDisplayMode'),
          }),

          archive: new Archiving({
            show: (
              Boolean(publisher.getStyle('showArchiveStatus')) &&
              publisher.getStyle('archiveStatusDisplayMode') !== 'off'
            ),
            archiving: false,
          }),

          muteButton: new MuteButton({
            muted: publishAudio === false,
            mode: chromeButtonMode.call(null, publisher.getStyle('buttonDisplayMode')),
          }),
        };

        audioLevelMeter = new AudioLevelMeter({
          mode: publisher.getStyle('audioLevelDisplayMode'),
        });

        const audioLevelTransformer = new AudioLevelTransformer();

        const audioWatcher = new RafRunner(() => {
          // @FIXME
          // We force the audio level value to be zero here if audio is disabled
          // because the AudioLevelMeter cannot currently differentiate
          // between video being disabled and audio being disabled.
          // To be fixed as part of OPENTOK-29865

          const audioLevel = !publishAudio ?
            0 : audioLevelTransformer.transform(publisher.loudness);
          audioLevelMeter.setValue(audioLevel);
        });

        audioLevelMeter.watchVisibilityChanged((visible) => {
          if (visible) {
            audioWatcher.start();
          } else {
            audioWatcher.stop();
          }
        });

        audioLevelMeter.audioOnly(!publishVideo && publishAudio);

        widgets.audioLevel = audioLevelMeter;

        if (widgetViewParam && widgetViewParam.domElement) {
          widgetView = widgetViewParam;

          chrome = new Chrome({
            parent: widgetView.domElement,
          }).set(widgets).on({
            muted: () => publisher.publishAudio(false),
            unmuted: () => publisher.publishAudio(true),
          });

          if (audioSource === null || audioSource === false) {
            chromeMixin.removeAudioTrack();
          }

          resolve();
        }
      };

      chromeMixin.reset = () => {
        // reject in case the chrome creating was still pending
        reject(new Error('Chrome still being created'));

        if (chrome) {
          chrome.destroy();
          chrome = null;
        }
      };

      chromeMixin.destroy = () => {
        chromeMixin.reset();
        cleanupJobs.releaseAll();
        uiPromise = null;
      };
    });

    uiPromise.catch((err) => {
      // Only log unexpected rejections
      if (!err || err.message !== 'Chrome still being created') {
        logging.error('createChromeMixin failed to setup UI', err);
      }
    });

    chromeMixin.setAudioOnly = (value) => {
      if (audioLevelMeter) {
        audioLevelMeter.audioOnly(value);
      }
    };

    chromeMixin.setArchivingStatus = (status) => {
      if (chrome) {
        chrome.archive.setArchiving(status);
      }
    };

    chromeMixin.setMuted = (muted) => {
      if (chrome && chrome.muteButton) {
        chrome.muteButton.muted(muted);
      }
    };

    chromeMixin.removeAudioTrack = () => {
      if (chrome && chrome.muteButton) {
        chrome.muteButton.remove();
      }
    };

    chromeMixin.addAudioTrack = () => {
      if (chrome && chrome.muteButton) {
        chrome.muteButton.add();
      }
    };

    StylableComponent(publisher, {
      showArchiveStatus: true,
      nameDisplayMode: 'auto',
      buttonDisplayMode: 'auto',
      audioLevelDisplayMode: shouldAllowAudio ? 'auto' : 'off',
      archiveStatusDisplayMode: 'auto',
      backgroundImageURI: null,
    }, showControls, (payload) => {
      logAnalyticsEvent('SetStyle', 'Publisher', payload, null, 0.1);
    });

    const onStyleValueChanged = (key, value) => {
      // enlist style change for when the the chrome is created
      uiPromise.then(() => {
        switch (key) {
          case 'nameDisplayMode':
            chrome.name.setDisplayMode(value);
            chrome.backingBar.setNameMode(value);
            break;

          case 'showArchiveStatus':
            logAnalyticsEvent('showArchiveStatus', 'styleChange', { mode: value ? 'on' : 'off' });
            chrome.archive.setShowArchiveStatus(Boolean(value));
            break;

          case 'archiveStatusDisplayMode':
            chrome.archive.setShowArchiveStatus(value !== 'off');
            break;

          case 'buttonDisplayMode':
            chrome.muteButton.setDisplayMode(value);
            chrome.backingBar.setMuteMode(value);
            break;

          case 'audioLevelDisplayMode':
            chrome.audioLevel.setDisplayMode(value);
            break;

          case 'backgroundImageURI':
            widgetView.setBackgroundImageURI(value);
            break;

          default:
        }
      });
    };
    publisher.on('styleValueChanged', onStyleValueChanged);
    cleanupJobs.add(() => publisher.off('styleValueChanged', onStyleValueChanged));

    return chromeMixin;
  };
};
