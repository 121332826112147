/* eslint-disable no-param-reassign */
const isScreenSharingSource = require('./isScreenSharingSource');

const supportsScalableScreenShare = properties => !!properties?.scalableScreenshare;

module.exports = (properties) => {
  if (!properties) {
    properties = {};
  }

  const isScreenshare = !!isScreenSharingSource(properties?.videoSource);

  // eslint-disable-next-line no-unused-expressions
  properties.capableSimulcastScreenshare = isScreenshare && supportsScalableScreenShare(properties);
};
