const assign = require('lodash/assign');

const generatePropertyFunction = (object, getter, setter) => {
  if (getter && !setter) {
    return () => getter.call(object);
  }

  if (getter && setter) {
    return (value) => {
      if (value !== undefined) {
        setter.call(object, value);
      }
      return getter.call(object);
    };
  }

  return (value) => {
    if (value !== undefined) {
      setter.call(object, value);
    }
  };
};

/**
 * @typedef getterSetterDefinition
 * @property {function(): any} get the function called when accessing the value
 * @property {function(any): void} set the function called when setting the value
 */

/**
 * Creates primitive getters/ setters on objects
 *
 * For every key in gettersSetters, a method will be added to object.
 *
 * When the method is called with no value, it will call the getter
 * When the method is called with a value, it will call the setter with the value
 *
 * @deprecated Prefer using real getters and setters
 *
 * @param {any} object
 * @param {Object.<string, getterSetterDefinition>} getterSetters
 */
module.exports = (object, getterSetters) => {
  const mixin = {};
  Object.keys(getterSetters).forEach((key) => {
    mixin[key] = generatePropertyFunction(
      object,
      getterSetters[key].get,
      getterSetters[key].set
    );
  });
  assign(object, mixin);
};
