const ExceptionCodes = require('../exception_codes');
const errors = require('../Errors');

const errorMap = {
  [ExceptionCodes.ANVIL_BADLY_FORMED_RESPONSE]: errors.BADLY_FORMED_RESPONSE,
  [ExceptionCodes.ANVIL_INVALID_HTTP_STATUS]: errors.INVALID_HTTP_STATUS,
  [ExceptionCodes.ANVIL_XDOMAIN_OR_PARSING_ERROR]: errors.XDOMAIN_OR_PARSING_ERROR,
  [ExceptionCodes.ANVIL_UNKNOWN_HTTP_ERROR]: errors.UNKNOWN_HTTP_ERROR,
  [ExceptionCodes.ANVIL_UNEXPECTED_ERROR_CODE]: errors.UNEXPECTED_ERROR_CODE,
  [ExceptionCodes.ANVIL_EMPTY_RESPONSE_BODY]: errors.EMPTY_RESPONSE_BODY,
  [ExceptionCodes.ANVIL_CONNECT_FAILED]: errors.CONNECT_FAILED,
  [ExceptionCodes.AUTHENTICATION_ERROR]: errors.AUTHENTICATION_ERROR,
  [ExceptionCodes.INVALID_SESSION_ID]: errors.INVALID_SESSION_ID,
  [ExceptionCodes.TERMS_OF_SERVICE_FAILURE]: errors.TERMS_OF_SERVICE_FAILURE,
  [ExceptionCodes.UNEXPECTED_SERVER_RESPONSE]: errors.UNEXPECTED_SERVER_RESPONSE,
};

/**
 * Maps an error from SessionInfo.get to its corresponding name
 * @param {number} code - Error code
 * @return {string|undefined} Error name
 */
function mapErrorCodeToName(code) {
  return errorMap[code];
}

module.exports = mapErrorCodeToName;
