const StateMachine = require('javascript-state-machine');

// @todo The big plan for connectivityState is to eventually use the state transitions to do
// what is necessary rather than have it duplicated and possible differences and mistakes
// around what is to occur when different states occur. Things like calling subscribeComplete
// logging, calling disconnect or destroy, should all be driven by the state of the connection
module.exports = ({ onInvalidTransition }) =>
  new StateMachine({
    init: 'disconnected',
    transitions: [
      { name: 'beginConnect', from: 'disconnected', to: 'connecting' },
      { name: 'connect', from: 'connecting', to: 'connected' },
      { name: 'fail', from: ['connecting', 'connected'], to: 'disconnected' },
      { name: 'cancel', from: ['connecting', 'connected'], to: 'disconnected' },
      { name: 'disconnect', from: ['connecting', 'connected'], to: 'disconnected' },
    ],
    methods: { onInvalidTransition },
  });
