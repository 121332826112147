const eventNames = require('../../helpers/eventNames');
const now = require('../../helpers/now');
const RafRunner = require('../RafRunner');
const Events = require('../events')();

module.exports = ({ subscriber, audioLevelSampler }) => {
  let loudness = 0;
  let loudnessLastAccess = 0;

  const audioLevelRunner = new RafRunner(() => {
    const listenerCount = subscriber.listenerCount(eventNames.AUDIO_LEVEL_UPDATED);
    const shouldStopWatchingAudio = () => now() - loudnessLastAccess > 10000 && listenerCount === 0;

    if (shouldStopWatchingAudio()) {
      audioLevelRunner.stop();
      return;
    }

    loudness = audioLevelSampler.sample();

    if (listenerCount > 0) {
      // dispatch this old event if someone is listening...
      subscriber.dispatchEvent(new Events.AudioLevelUpdatedEvent(loudness));
    }
  });

  Object.defineProperty(subscriber, 'loudness', {
    get() {
      audioLevelRunner.start();
      loudnessLastAccess = now();
      return loudness;
    },
    set() {
      throw new TypeError('Cannot set readonly property loudness');
    },
    configurable: true,
  });

  if (subscriber.listenerCount(eventNames.AUDIO_LEVEL_UPDATED)) {
    audioLevelRunner.start();
  }

  subscriber.on('audioLevelUpdated:added', () => { audioLevelRunner.start(); });
  subscriber.once(eventNames.SUBSCRIBER_DESTROYED, () => {
    audioLevelRunner.stop();
  });

  return {
    destroy() {
      audioLevelSampler.destroy();
      audioLevelRunner.stop();
    },
  };
};
