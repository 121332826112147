const assign = require('lodash/assign');
const omit = require('lodash/omit');

const filters = {
  Edge(iceServers) {
    let foundTurn = false;

    return iceServers
      .map(((server) => {
        if (!server) {
          return undefined;
        }

        const urls = server.urls || (server.url && [server.url]);

        if (!urls) {
          return undefined;
        }

        const filteredUrls = urls.filter((url) => {
          const turnOk = (
            url.indexOf('turn:') === 0 &&
            url.indexOf('transport=udp') !== -1 &&
            url.indexOf('turn:[') === -1 &&
            !foundTurn
          );

          if (turnOk) {
            foundTurn = true;
            return true;
          }

          return url.indexOf('stun:') === 0;
        });

        if (filteredUrls.length === 0) {
          return undefined;
        }

        return assign(
          omit(server, ['url', 'urls']),
          { urls: filteredUrls }
        );
      }))
      .filter(server => server !== undefined);
  },
  Safari(iceServers) {
    return iceServers
      .map(((server) => {
        if (server.urls !== undefined && server.url === undefined) {
          return server;
        }

        const urls = server.urls || (server.url && [server.url]);

        return assign(
          omit(server, ['url', 'urls']),
          { urls }
        );
      }));
  },
};

module.exports = function filterIceServers(env, iceServers) {
  let browserName = env.name;

  if (env.isEdge) {
    browserName = env.isLegacyEdge ? 'Edge' : 'ChromiumEdge';
  }

  const filter = filters[browserName];

  if (filter) {
    return filter(iceServers);
  }

  return iceServers;
};
