const Widget = require('./behaviour/widget.js');
const OTHelpers = require('../../common-js-helpers/OTHelpers.js');

module.exports = function AudioBlockedIndicator(options) {
  let audioBlocked = false;

  const updateClasses = (element) => {
    const shouldDisplay = ['auto', 'on'].indexOf(this.getDisplayMode()) > -1;

    OTHelpers.removeClass(element, [
      'OT_audio-blocked',
      'OT_active',
    ].join(' '));

    if (!shouldDisplay) {
      return;
    }

    if (audioBlocked) {
      OTHelpers.addClass(element, 'OT_audio-blocked');
    }

    OTHelpers.addClass(element, 'OT_active');
  };

  this.setAudioBlocked = (value) => {
    audioBlocked = value;
    updateClasses(this.domElement);
  };

  // Mixin common widget behaviour
  Widget(this, {
    mode: options.mode || 'auto',
    nodeName: 'div',
    htmlAttributes: {
      className: 'OT_audio-blocked-indicator',
    },
  });

  const parentSetDisplayMode = this.setDisplayMode.bind(this);
  this.setDisplayMode = (mode) => {
    parentSetDisplayMode(mode);
    updateClasses(this.domElement);
  };
};
