const eventHelper = require('./eventHelper');
const promiseFinally = require('promise-finally').default;
const pTimeout = require('p-timeout');

// eslint-disable-next-line no-unused-vars
const typeVideoElementFacade = require('./video_element/index')();

/**
 * Wait for the video to be playing
 *
 * @param {typeVideoElementFacade} videoElementFacade
 * @param {Number} maxWaitTime
 *
 * @returns Promise<Boolean> true if we think the video is playing, false if we timeout
 */
function waitForVideoToBePlaying(
  videoElementFacade,
  maxWaitTime = 5000
) {
  const videoElementFacadeEvents = eventHelper(videoElementFacade);

  const promise = new Promise((resolve) => {
    videoElementFacadeEvents.on('timeupdate', resolve);
    videoElementFacadeEvents.on('loadedmetadata', resolve);
  });

  return promiseFinally(
    pTimeout(promise, maxWaitTime, () => false),
    () => videoElementFacadeEvents.removeAll()
  );
}

module.exports = waitForVideoToBePlaying;
