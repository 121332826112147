// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, no-void */

const env = require('../../helpers/env');
const haveGetDisplayMedia = require('../../helpers/haveGetDisplayMedia').default;

const isSupportedInThisBrowser = () => {
  const { isFirefox } = env;

  if (!isFirefox) {
    return false;
  }

  // Don't use plugin if getDisplayMedia is supported
  return !haveGetDisplayMedia;
};

module.exports = {
  isSupportedInThisBrowser: isSupportedInThisBrowser(),
  autoRegisters: true,
  extensionRequired: false,
  extensionInstalled: false,
  getConstraintsShowsPermissionUI: false,
  sources: {
    screen: true,
    application: false,
    window: true,
    browser: false,
  },
  register() {
    return {
      isInstalled(callback) {
        // The extension sets window.OTScreenSharing in pages loaded from a matching domain
        callback(typeof OTScreenSharing === 'object');
      },
      getConstraints(source, constraints, callback) {
        constraints.video = {
          mediaSource: source,
        };

        // copy constraints under the video object and removed them from the root constraint object
        if (constraints.browserWindow) {
          constraints.video.browserWindow = constraints.browserWindow;
          delete constraints.browserWindow;
        }
        if (typeof constraints.scrollWithPage !== 'undefined') {
          constraints.video.scrollWithPage = constraints.scrollWithPage;
          delete constraints.scrollWithPage;
        }

        callback(void 0, constraints);
      },
    };
  },
};
