// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, global-require, no-underscore-dangle, func-names */

const assign = require('lodash/assign');
const pick = require('lodash/pick');
const find = require('lodash/find');
const eventing = require('../../helpers/eventing');
const shouldForceTurn = require('../../helpers/shouldForceTurn');
const setICEConfigWithForcedTurn = require('../../helpers/setIceConfigWithForcedTurn');
const getMaxBitrateForResolution = require('./getMaxBitrateForResolution');

module.exports = function PublisherPeerConnectionFactory(deps = {}) {
  const getStatsHelpers = deps.getStatsHelpers || require('./get_stats_helpers.js');
  const OTHelpers = deps.OTHelpers || require('../../common-js-helpers/OTHelpers.js');
  const PeerConnection = deps.PeerConnection || require('./peer_connection.js')();
  const setCertificates = deps.setCertificates || require('./set_certificates.js')();
  const watchAudioAcquisition = deps.watchAudioAcquisition || require('./watchAudioAcquisition.js');

  /**
   * @typedef {object} PublisherPeerConnectionConfig
   * @property {function(string, string=, object=, object=, boolean=)} logAnalyticsEvent
   * @property {Connection} remoteConnection
   * @property {boolean} reconnection
   * @property {MediaStream} webRTCStream
   * @property {object<string, object>} channels
   * @property {boolean} capableSimulcastStreams
   * @property {boolean} overrideSimulcastEnabled
   * @property {string} subscriberUri
   * @property {object<string, object>} offerOverrides
   * @property {object<string, object>} answerOverrides
   * @property {string} sourceStreamId
   * @property {string} isP2pEnabled
   */

  /**
   * Abstracts PeerConnection related stuff away from Publisher.
   *
   * Responsible for:
   * * setting up the underlying PeerConnection (delegates to PeerConnections)
   * * triggering a connected event when the Peer connection is opened
   * * triggering a disconnected event when the Peer connection is closed
   * * providing a destroy method
   * * providing a processMessage method
   *
   * Once the PeerConnection is connected and the video element playing it triggers
   * the connected event
   *
   * Triggers the following events
   * * connected
   * * disconnected
   *
   * @class PublisherPeerConnection
   * @constructor
   *
   * @param {PublisherPeerConnectionConfig} config
   */
  return function PublisherPeerConnection({
    iceConfig,
    webRTCStream,
    channels,
    sendMessage,
    capableSimulcastStreams,
    overrideSimulcastEnabled,
    logAnalyticsEvent,
    offerOverrides,
    answerOverrides,
    sourceStreamId,
    isP2pEnabled,
    sessionId,
    keyStore,
    sFrameClientStore,
    isE2ee,
  }) {
    let _peerConnection;
    let _awaitingIceRestart = false;
    let _cancelWatchAudioAcquisition;

    // Private
    const _onPeerClosed = function () {
      this.destroy();
      if (_awaitingIceRestart) {
        _awaitingIceRestart = false;
        this.trigger('iceRestartFailure');
      }
      this.trigger('disconnected');
    };

    const _onPeerError = function ({ reason, prefix }) {
      this.trigger('error', { reason, prefix });
    };

    const _onIceConnectionStateChange = function (state) {
      if (_awaitingIceRestart && _peerConnection.iceConnectionStateIsConnected()) {
        _awaitingIceRestart = false;
        this.trigger('iceRestartSuccess');
      }

      // watch for the Chrome bug where audio can't be acquired
      // can not use iceConnectionStateIsConnected since it is too broad
      if (state === 'connected') {
        if (OTHelpers.env.name === 'Chrome') {
          // cancel any pending watcher (in case of ice restart for example)
          if (_cancelWatchAudioAcquisition) {
            _cancelWatchAudioAcquisition();
          }
          _cancelWatchAudioAcquisition = watchAudioAcquisition(
            _peerConnection.getStats.bind(_peerConnection),
            () => this.trigger('audioAcquisitionProblem')
          );
        }
        this.trigger('connected');
      }

      this.trigger('iceConnectionStateChange', state);
    };

    eventing(this);

    // / Public API

    this.startEncryption = (connectionId) => {
      if (_peerConnection) {
        _peerConnection.startEncryption(connectionId);
      }
    };

    this.changeMediaDirectionToInactive = () => {
      if (_peerConnection) {
        _peerConnection.changeMediaDirectionToInactive();
      }
    };

    this.changeMediaDirectionToRecvOnly = () => {
      if (_peerConnection) {
        _peerConnection.changeMediaDirectionToRecvOnly();
      }
    };

    this.getDataChannel = function (label, options, completion) {
      _peerConnection.getDataChannel(label, options, completion);
    };

    this.getSourceStreamId = () => _peerConnection.getSourceStreamId();

    this.destroy = function () {
      if (_cancelWatchAudioAcquisition) {
        _cancelWatchAudioAcquisition();
        _cancelWatchAudioAcquisition = null;
      }

      // Clean up our PeerConnection
      if (_peerConnection) {
        _peerConnection.disconnect();
        _peerConnection = null;
      }

      this.off();
    };

    this.processMessage = function (type, message) {
      _peerConnection.processMessage(type, message);
    };

    this.addTrack = function (track, stream, callback) {
      return _peerConnection.addTrack(track, stream, callback);
    };

    this.removeTrack = function (RTCRtpSender) {
      return _peerConnection.removeTrack(RTCRtpSender);
    };

    this.getLocalStreams = function () {
      return _peerConnection.getLocalStreams();
    };

    // Init
    this.init = function (rumorIceServers, completion) {
      if (shouldForceTurn(sourceStreamId)) {
        setICEConfigWithForcedTurn(iceConfig);
      }
      const pcConfig = {
        iceConfig: !iceConfig.needRumorIceServersFallback ? iceConfig : assign(iceConfig, {
          servers: [...rumorIceServers, ...iceConfig.servers],
        }),
        channels,
        capableSimulcastStreams,
        overrideSimulcastEnabled,
      };

      setCertificates(pcConfig, (err, pcConfigWithCerts) => {
        if (err) {
          completion(err);
          return;
        }

        const peerConnectionConfig = assign(
          {
            logAnalyticsEvent,
            isPublisher: true,
            offerOverrides,
            answerOverrides,
            sourceStreamId,
            p2p: isP2pEnabled,
            sessionId,
            keyStore,
            sFrameClientStore,
            isE2ee,
          },
          pcConfigWithCerts
        );

        _peerConnection = new PeerConnection(
          assign({ sendMessage }, peerConnectionConfig)
        );

        _peerConnection.on({
          close: _onPeerClosed,
          error: _onPeerError,
          qos: qos => this.trigger('qos', qos),
          iceConnectionStateChange: _onIceConnectionStateChange,
        }, this);

        _peerConnection.addLocalStream(webRTCStream)
          .then(() => {
            completion(undefined);
          })
          .catch(completion);
      });
    };

    this.getSenders = function () {
      return _peerConnection.getSenders();
    };

    this.iceRestart = function () {
      if (_peerConnection) {
        _awaitingIceRestart = true;
        _peerConnection.iceRestart();
      }
    };

    this.hasRelayCandidates = () => _peerConnection.hasRelayCandidates();

    this.iceConnectionStateIsConnected = function () {
      return _peerConnection.iceConnectionStateIsConnected();
    };

    this.findAndReplaceTrack = (oldTrack, newTrack) => (
      _peerConnection.findAndReplaceTrack(oldTrack, newTrack)
    );

    this._testOnlyGetFramesEncoded = () => new Promise((resolve, reject) => {
      _peerConnection.getStats((err, stats) => {
        if (err) {
          reject(err);
          return;
        }

        const videoStat = find(stats, stat => (
          getStatsHelpers.isVideoStat(stat, stats) && getStatsHelpers.isOutboundStat(stat)
        ));

        if (!videoStat) {
          reject(new Error('Could not find framesEncoded in getStats report'));
          return;
        }

        resolve(pick(videoStat, ['timestamp', 'framesEncoded']));
      });
    });

    this.getStats = callback => _peerConnection.getStats(callback);

    this.getRtcStatsReport = callback => _peerConnection.getRtcStatsReport(callback);

    this.setP2PMaxBitrate = async () => {
      if (this.getSourceStreamId() !== 'P2P') return;

      const sender = this.getSenders().find(({ track: { kind } }) => kind === 'video');
      if (!sender) return;

      const { width, height } = sender?.track?.getSettings?.() || {};

      const maxBitrate = getMaxBitrateForResolution(width, height);
      const sendParameters = sender.getParameters();
      sendParameters.encodings.forEach((encoding) => {
        encoding.maxBitrate = maxBitrate; // eslint-disable-line no-param-reassign
      });
      try {
        await sender.setParameters(sendParameters);
      } catch (e) {
        // ignore error
      }
    };
  };
};
