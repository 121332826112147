// This strategy will work on modern browsers
const getDeviceIdFromSettings = videoTrack =>
  videoTrack.getSettings && videoTrack.getSettings().deviceId;

// Legacy browsers, like Firefox 52, require this strategy
const getDeviceIdFromConstraints = (videoTrack) => {
  let deviceId;

  if (videoTrack.getConstraints && videoTrack.getConstraints().deviceId) {
    deviceId = videoTrack.getConstraints().deviceId.exact;
  }

  return deviceId;
};

// Look for the current device Id in the device list.
const getDeviceIdFromDevicesList = (videoTrack, videoDevices) => {
  let deviceId;
  if (videoDevices && videoDevices.length) {
    const currentDevice = videoDevices.find(device => device.label === videoTrack.label);
    if (currentDevice) {
      deviceId = currentDevice.deviceId;
    }
  }
  return deviceId;
};

const getDeviceId = (videoTrack, videoDevices) =>
  getDeviceIdFromSettings(videoTrack) ||
  getDeviceIdFromConstraints(videoTrack) ||
  getDeviceIdFromDevicesList(videoTrack, videoDevices);

module.exports = (stream, videoDevices) => {
  if (!stream) {
    return null;
  }

  const [videoTrack] = stream.getVideoTracks();

  if (!videoTrack) {
    return null;
  }

  return getDeviceId(videoTrack, videoDevices);
};
