// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable one-var, no-restricted-syntax, no-prototype-builtins */
/* eslint-disable no-continue, prefer-const, no-bitwise, no-mixed-operators, no-param-reassign */

import uuid from 'uuid';
import { TextEncoder, TextDecoder } from './encoding';
import * as RumorMessageTypes from './RumorMessageTypes';

const toArrayBuffer = (buffer) => {
  const ab = new ArrayBuffer(buffer.length);
  const view = new Uint8Array(ab);
  for (let i = 0; i < buffer.length; ++i) {
    view[i] = buffer[i];
  }
  return ab;
};

// @references
// * https://tbwiki.tokbox.com/index.php/Rumor_Message_Packet
// * https://tbwiki.tokbox.com/index.php/Rumor_Protocol
//
export default class RumorMessage {
  type;
  toAddress;
  headers;
  data;

  constructor(type, toAddress, headers, data) {
    this.type = type;
    this.toAddress = toAddress;
    this.headers = headers;
    this.data = data;

    this.fromAddress = this.headers['X-TB-FROM-ADDRESS'];
    this.transactionId = this.headers['TRANSACTION-ID'];
    this.status = this.headers.STATUS;

    // Only status messages will have a status header. So a missing status header
    // does not indicate an eror.
    this.isError = this.status && this.status[0] !== '2';
  }

  serialize() {
    let strArray,
      dataView,
      i,
      j;
    let offset = 8;
    let cBuf = 7;
    const address = [];
    const headerKey = [];
    const headerVal = [];

    // The number of addresses
    cBuf++;

    // Write out the address.
    for (i = 0; i < this.toAddress.length; i++) {
      /* jshint newcap:false */
      address.push(new TextEncoder('utf-8').encode(this.toAddress[i]));
      cBuf += 2;
      cBuf += address[i].length;
    }

    // The number of parameters
    cBuf++;

    // Write out the params
    i = 0;

    for (const key in this.headers) { // eslint-disable-line one-var
      if (!this.headers.hasOwnProperty(key)) {
        continue;
      }
      headerKey.push(new TextEncoder('utf-8').encode(key));
      headerVal.push(new TextEncoder('utf-8').encode(this.headers[key]));
      cBuf += 4;
      cBuf += headerKey[i].length;
      cBuf += headerVal[i].length;

      i++;
    }

    dataView = new TextEncoder('utf-8').encode(this.data);
    cBuf += dataView.length;

    // Let's allocate a binary blob of this size
    const buffer = new ArrayBuffer(cBuf);
    const uint8View = new Uint8Array(buffer, 0, cBuf);

    // We don't include the header in the lenght.
    cBuf -= 4;

    // Write out size (in network order)
    uint8View[0] = (cBuf & 0xFF000000) >>> 24;
    uint8View[1] = (cBuf & 0x00FF0000) >>> 16;
    uint8View[2] = (cBuf & 0x0000FF00) >>> 8;
    uint8View[3] = (cBuf & 0x000000FF) >>> 0;

    // Write out reserved bytes
    uint8View[4] = 0;
    uint8View[5] = 0;

    // Write out message type
    uint8View[6] = this.type;
    uint8View[7] = this.toAddress.length;

    // Now just copy over the encoded values..
    for (i = 0; i < address.length; i++) {
      strArray = address[i];
      uint8View[offset++] = strArray.length >> 8 & 0xFF;
      uint8View[offset++] = strArray.length >> 0 & 0xFF;
      for (j = 0; j < strArray.length; j++) {
        uint8View[offset++] = strArray[j];
      }
    }

    uint8View[offset++] = headerKey.length;

    // Write out the params
    for (i = 0; i < headerKey.length; i++) {
      strArray = headerKey[i];
      uint8View[offset++] = strArray.length >> 8 & 0xFF;
      uint8View[offset++] = strArray.length >> 0 & 0xFF;
      for (j = 0; j < strArray.length; j++) {
        uint8View[offset++] = strArray[j];
      }

      strArray = headerVal[i];
      uint8View[offset++] = strArray.length >> 8 & 0xFF;
      uint8View[offset++] = strArray.length >> 0 & 0xFF;
      for (j = 0; j < strArray.length; j++) {
        uint8View[offset++] = strArray[j];
      }
    }

    // And finally the data
    for (i = 0; i < dataView.length; i++) {
      uint8View[offset++] = dataView[i];
    }

    return buffer;
  }

  static deserialize(buffer) {
    if (global.Buffer && global.Buffer.isBuffer(buffer)) {
      buffer = toArrayBuffer(buffer);
    }

    let type,
      strView,
      headerlen,
      headers,
      keyStr,
      valStr,
      length,
      i;
    let offset = 8;
    const uint8View = new Uint8Array(buffer);

    type = uint8View[6];
    const address = [];

    for (i = 0; i < uint8View[7]; i++) {
      length = uint8View[offset++] << 8;
      length += uint8View[offset++];
      strView = new Uint8Array(buffer, offset, length);
      /* jshint newcap:false */
      address[i] = new TextDecoder('utf-8').decode(strView);
      offset += length;
    }

    headerlen = uint8View[offset++];
    headers = {};

    for (i = 0; i < headerlen; i++) {
      length = uint8View[offset++] << 8;
      length += uint8View[offset++];
      strView = new Uint8Array(buffer, offset, length);
      keyStr = new TextDecoder('utf-8').decode(strView);
      offset += length;

      length = uint8View[offset++] << 8;
      length += uint8View[offset++];
      strView = new Uint8Array(buffer, offset, length);
      valStr = new TextDecoder('utf-8').decode(strView);
      headers[keyStr] = valStr;
      offset += length;
    }

    const dataView = new Uint8Array(buffer, offset);
    const data = new TextDecoder('utf-8').decode(dataView);

    return new RumorMessage(type, address, headers, data);
  }

  static Connect(uniqueId, notifyDisconnectAddress) {
    const headers = {
      uniqueId,
      'TRANSACTION-ID': uuid(),
      notifyDisconnectAddress,
    };

    return new RumorMessage(RumorMessageTypes.CONNECT, [], headers, '');
  }

  static Disconnect(reconnect) {
    return new RumorMessage(
      RumorMessageTypes.DISCONNECT,
      [],
      { reconnect },
      ''
    );
  }

  static Subscribe(topics) {
    return new RumorMessage(RumorMessageTypes.SUBSCRIBE, topics, {}, '');
  }

  static Unsubscribe(topics) {
    return new RumorMessage(RumorMessageTypes.UNSUBSCRIBE, topics, {}, '');
  }

  static Publish(topics, message, headers) {
    return new RumorMessage(RumorMessageTypes.MESSAGE, topics, headers || {}, message || '');
  }

  static Status(topics, headers) {
    return new RumorMessage(RumorMessageTypes.STATUS, topics, headers || {}, '');
  }

  // This message is used to implement keepalives on the persistent
  // socket connection between the client and server. Every time the
  // client sends a PING to the server, the server will respond with
  // a PONG.
  static Ping() {
    return new RumorMessage(RumorMessageTypes.PING, [], {}, '');
  }
}
