// Deserialising a Raptor message mainly means doing a JSON.parse on it.
// We do decorate the final message with a few extra helper properies though.
//
// These include:
// * typeName: A human readable version of the Raptor type. E.g. STREAM instead of 102
// * actionName: A human readable version of the Raptor action. E.g. CREATE instead of 101
// * signature: typeName and actionName combined. This is mainly for debugging. E.g. A type
//    of 102 and an action of 101 would result in a signature of "STREAM:CREATE"
//
module.exports = function deserializeMessage(msg) {
  if (msg.length === 0) { return {}; }

  const message = JSON.parse(msg);
  const bits = message.uri.substr(1).split('/');

  // Remove the Raptor protocol version
  bits.shift();
  if (bits[bits.length - 1] === '') { bits.pop(); }

  message.params = {};
  for (let i = 0, numBits = bits.length; i < numBits - 1; i += 2) {
    message.params[bits[i]] = bits[i + 1];
  }

  // extract the resource name. We special case 'channel' slightly, as
  // 'subscriber_channel' or 'stream_channel' is more useful for us
  // than 'channel' alone.
  if (bits.length % 2 === 0) {
    if (bits[bits.length - 2] === 'channel' && bits.length > 6) {
      message.resource = `${bits[bits.length - 4]}_${bits[bits.length - 2]}`;
    } else {
      message.resource = bits[bits.length - 2];
    }
  } else if (bits[bits.length - 1] === 'channel' && bits.length > 5) {
    message.resource = `${bits[bits.length - 3]}_${bits[bits.length - 1]}`;
  } else {
    message.resource = bits[bits.length - 1];
  }

  message.signature = `${message.resource}#${message.method}`;
  return message;
};
