const Events = require('./events.js')();
const eventing = require('../helpers/eventing');

module.exports = function Archive(id, name, status) {
  this.id = id;
  this.name = name;
  this.status = status;
  this._ = {};

  eventing(this);

  // Mass update, called by Raptor.Dispatcher
  this._.update = (attributes) => {
    Object.keys(attributes).forEach((key) => {
      const oldValue = this[key];
      this[key] = attributes[key];

      const event = new Events.ArchiveUpdatedEvent(this, key, oldValue, this[key]);
      this.dispatchEvent(event);
    });
  };

  this.destroy = () => {};
};
